import {Input, Picker, Space} from "antd-mobile";
import {useState} from "react";
import './MeasureInput.css'
import {DownOutline} from "antd-mobile-icons";

export default function MeasureInput({value, onChange, options, unit = {}, onMeasureChange}: any) {
    const [visible, setVisible]: any = useState(false)

    return <div className="measure-input">
        <Input value={value} onChange={onChange} type='number' clearable onClear={() => onChange(0)}/>
        <div className='choose-button' onClick={() => setVisible(true)}>
            <Space>
                <span>{unit.unit}</span>
                <DownOutline fontSize={10}/>
            </Space>
        </div>
        <Picker
            columns={[options?.map((e: any) => ({label: e.unit, value: e.id}))]}
            visible={visible}
            onClose={() => setVisible(false)}
            value={unit.id}
            onConfirm={onMeasureChange}
        />
    </div>
}