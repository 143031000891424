// illness.tsx

import { useEffect, useState } from "react";
import { Tabs } from "antd-mobile";
import { useThrottleFn } from 'ahooks'
import "./illness.css"
import { useSelector } from "react-redux";
import { selectJsonRequest, selectUser } from "../state/global";
import { Link } from "react-router-dom";
import ToTop from "../components/ToTop";
import SearchBySystem from "../module.search/SearchBySystem";
import OpenAppBanner from "../components/OpenAppBanner";
import OpenAppButton from "../components/OpenAppButton";
import PageEnter from "../functions/umeng/pageEnter";

const tabHeight = 107

export function Illness(params: any) {
    const [fullScreen, setFullScreen] = useState(false)

    const [activeKey, setActiveKey] = useState('内科')

    // 列表数据
    const [data, setData]: any = useState([])
    // 分页数据
    const [filterData, setFilterData]: any = useState([])
    // 选择的筛选组
    const [filterCurrentGroup, setFilterCurrentGroup]: [string | null, Function] = useState(null)
    // 选择的筛选项目
    const [filterSeleted, setFilterSeleted]: any = useState([])
    // json请求客户端
    const JsonRequest = useSelector(selectJsonRequest)

    const { run: handleScroll } = useThrottleFn(
        () => {
            let currentKey = filterData[0].cateName
            for (const item of filterData) {
                const element = document.getElementById(`anchor-${item.cateName}`)
                if (!element) continue
                const rect = element.getBoundingClientRect()
                if (rect.top <= tabHeight) {
                    currentKey = item.cateName
                } else {
                    break
                }
            }
            setActiveKey(currentKey)
        },
        {
            leading: true,
            trailing: true,
            wait: 100,
        }
    )

    // 当前登录的用户
    const user = useSelector(selectUser)

    useEffect(() => {
        // 记录数据
        PageEnter("PageModuleListEnter", "疾病", { path: window.location.pathname, userId: user?.id })
    }, [])


    useEffect(() => {
        const content = document.querySelector(".page .content")

        content?.addEventListener('scroll', handleScroll)

        reloadFilter()
            .then((groupId) => reloadData(groupId, filterSeleted))

        return () => {
            content?.removeEventListener('scroll', handleScroll)
        }


    }, [filterCurrentGroup, filterSeleted])

    // 加载数据
    const reloadData = (filterCurrentGroup: string | null, filterSeleted: Array<string> | null) => {
        let _filterSeleted: any = filterSeleted || []
        // 如果没有选择筛选项，则删掉
        if (_filterSeleted.length === 0)
            _filterSeleted = null
        JsonRequest.get("/v1/contents/cate-nests", {
            systemCode: "illness",
            groupId: filterCurrentGroup,
            cateIds: _filterSeleted?.map(({ id }: any) => id)
        })
            .then(({ data }) => setData(data))
    }


    // 加载筛选项
    const reloadFilter = async () => {
        if (!!filterCurrentGroup) return filterCurrentGroup;

        const { data } = await JsonRequest.get("/v1/content/cates/group-trees", { systemCode: "illness" })
        setFilterData(data[0]?.trees)
        let _filterCurrentGroup = filterCurrentGroup
        if (filterSeleted.length === 0) {
            // state的set方法又延迟，不能及时生效。这里需要强行设置
            _filterCurrentGroup = data[0]?.id
            setFilterCurrentGroup(_filterCurrentGroup)
        }
        return _filterCurrentGroup
    }

    // 单个项目的样式
    const viewItem = (item: any) => {
        switch (item.type) {
            case "content_Cate":
                return <section className="block cate" key={item.id}>
                    <span className="title no-sticky" id={`anchor-${item.name}`}>{item.name}</span>
                    <div className="tags">
                        {item.sons?.map(viewItem)}
                    </div>
                </section>
            case "content":
                return <Link key={item.id} id={item.id} state={{ title: item.name, back: window.location.pathname }} to={`/illness/detail/${item.id}`}
                    className="tag">{item.name}</Link>

            default:
                break;
        }
    }


    return <section className="page illness-index">
        <section className="search">
            <SearchBySystem placeholder="搜索疾病" systemCode='illness' onFullScreen={() => setFullScreen(true)}
                onBack={() => setFullScreen(false)} />
        </section>

        <section className={`filter ${fullScreen ? "hide" : ""}`}>
            <Tabs
                activeKey={activeKey}
                onChange={key => {
                    const top = document.getElementById(`anchor-${key}`)?.offsetTop
                    document.querySelector(".page .content")?.scrollTo({
                        top: top || 0,
                        behavior: "smooth"
                    })
                }}
            >
                {filterData.map((item: any) => (
                    <Tabs.Tab title={item.cateName} key={item.cateName} />
                ))}
            </Tabs>
        </section>
        <section className={`content ${fullScreen ? "hide" : ""}`}>
            <section className="tag-list">
                {data?.map(viewItem)}
            </section>
        </section>

        <ToTop />
        <OpenAppBanner hide={fullScreen} app="zj" title="中济经络穴位" summary="中医经络穴位针灸的3D模型活地图">
            <OpenAppButton url="https://www.lighttcm.com/invite" appid="wx96950494c868cde4" iOSMarketUrl="itms-apps://itunes.apple.com/app/id1554030035" androidMarketUrl="market://details?id=com.mc.acupoint_3d" />
        </OpenAppBanner>
    </section>
}

