export default async function filtedTotalNumber(jsonRequest: any, systemCode: string,
                                                filterSeleted: Array<{ id: string }>,
                                                meridianType = null) {
    let _filterSeleted: any = filterSeleted || []
    // 如果没有选择筛选项，则删掉
    if (_filterSeleted.length === 0)
        _filterSeleted = null
    const {data} = await jsonRequest.get("/v1/contents/number", {
        systemCode,
        meridianType,
        cateIds: _filterSeleted?.map(({id}: any) => id)
    })
    
    return data
}