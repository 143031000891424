import { createSlice } from '@reduxjs/toolkit';
import JsonRequest from '../components/jsonRequest'

const user = JSON.parse(sessionStorage.getItem("sign-in-user") || "null")

const initialState = {
    // 当前用户
    user: user,
    // 导航栏当前激活的key
    navActiveKey: null,
    // json请求客户端
    jsonRequest: JsonRequest.tokenClient(user),
    // 是否打开了开发者工具
    devToolsOpened: false,
    // 缓存初始化数据，用于加快第二次进入的速度
    cacheData: {},
    // unionid
    unionID: null,
    // 是否在微信里
    inWeChat: /micromessenger/.test(window.navigator.userAgent.toLowerCase()),
    // 分享给下一个人的code
    nextTrackCode: localStorage.getItem("next-track-code") || ''
};

export const counterSlice = createSlice({
    name: 'global',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // 更新开发者工具是否打开
        updateDevToolsOpened: (state, action) => {
            return { ...state, devToolsOpened: action.payload, jsonRequest: JsonRequest.tokenClient(state.user, action.payload) }
        },

        // 设置当前活跃的导航项
        navActive: (state, action) => {
            state.navActiveKey = action.payload
        },

        // 用户登录
        signIn: (state, action) => {
            sessionStorage.setItem("sign-in-user", JSON.stringify(action.payload))
            return { ...state, user: action.payload, jsonRequest: JsonRequest.tokenClient(state.user, state.devToolsOpened) }
        },

        // 用户退出登录
        signOut: (state) => {
            localStorage.setItem("sign-in-user", "null")
            localStorage.setItem("current-account", "null")
            return { ...state, user: null, account: null }
        },

        // 设置客户端
        setClient: (state, action) => {
            return { ...state, jsonRequest: action.payload }
        },

        // 设置数据
        setCacheData: (state, { payload: cacheData }) => {
            if (!cacheData) {
                return {...state, cacheData: {}}
            }
            let data = state.cacheData
            return {...state, cacheData: {...data, [cacheData.key]: cacheData.value}}
        },

        // 设置数据
        setUnionID: (state, { payload: { key, value } }) => {
            let data = state.cacheData
            return { ...state, cacheData: { ...data, [key]: value } }
        },
        setNextTrackCode:(state, { payload }) => {
            localStorage.setItem("next-track-code", payload)
            return { ...state, nextTrackCode: payload}
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading';
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle';
    //             state.value += action.payload;
    //         });
    // },
});


//解构赋值语法，将 counterSlice.actions 对象中的属性分别赋值给了相应的常量
export const { setNextTrackCode,navActive: NavActive, signIn, signOut, setClient, updateDevToolsOpened, setCacheData, setUnionID } = counterSlice.actions;

//选择器函数，收 state 参数并从 state.global 对象中获取相应的属性值
export const selectSocket = (state: { global: { socket: any; }; }) => state.global.socket;
export const selectUser = (state: { global: { user: any; }; }) => state.global.user;
export const selectNavActiveKey = (state: { global: { navActiveKey: any; }; }) => state.global.navActiveKey;
export const selectJsonRequest = (state: { global: { jsonRequest: any; }; }): JsonRequest => state.global.jsonRequest;
export const selectDevToolsOpened = (state: { global: { devToolsOpened: any; }; }) => state.global.devToolsOpened;
export const selectCacheData = (state: { global: { cacheData: any; }; }) => state.global.cacheData;
export const selectUnionID = (state: { global: { unionID: any; }; }) => state.global.unionID;
export const selectInWeChat = (state: { global: { inWeChat: any; }; }) => state.global.inWeChat;
export const selectNextTrackCode = (state: { global: { nextTrackCode: any; }; }) => state.global.nextTrackCode;


export default counterSlice.reducer;