import {Image, ImageViewer, Slider, Swiper} from "antd-mobile"
import {Link} from "react-router-dom"

import "./Media.css"
import {useState} from "react"
import IconAudio from "./IconAudio"
import CustomLoading from "../../components/CustomLoading"


export default function Media({item, info, group ,sons}: any) {

    // 多图滑动展示
    const [multiPictureSliderValue, setMultiPictureSliderValue]: any = useState(0)

    const TitleRender = ({noAudio = false}: any) => {
        return item.mediaName && <div className="title" id={`anchor-${item?.mediaId}`}>
            <span className="text">{item.mediaName}</span>
            {item?.extData?.audio?.url && <IconAudio src={item?.extData?.audio?.url} info={info}/>}
        </div>
    }

    switch (item.mediaType) {
        case "group":
            return <div key={item.mediaId} className="media group">
                <TitleRender/>
                <div className={group ? 'content hide-mask' : 'content'}>
                    {item.sons.map((item: any) => <Media key={item.mediaId} item={item} info={info}/>)}
                    {
                        sons &&
                        <div className="hide-mask">
                            <Media key={sons.mediaId} item={sons} nfo={info}/>
                        </div>
                    }
                </div>

            </div>
        case "string":
            return <div key={item.mediaId} className="media string">
                <TitleRender/>

                <div className="content"
                     dangerouslySetInnerHTML={{__html: item.extData.description?.replaceAll("\n", "<br/>")}}>
                </div>
            </div>
        case "rich_text":
            return <div key={item.mediaId} className="media rich_text">
                <TitleRender/>
                <div className="content"
                     dangerouslySetInnerHTML={{__html: item.extData.description}}>
                </div>
            </div>
        case "picture":
            return <div key={item.mediaId} className="media picture">
                <TitleRender/>
                <div className="content" >
                    <Image lazy placeholder={<CustomLoading height={200}/>} src={item.extData?.picturePath}
                           onClick={() => {
                               ImageViewer.show({image: item.extData?.picturePath})
                           }}></Image>
                </div>
            </div>
        case "video":
            return <div key={item.mediaId} className="media video">
                <TitleRender/>
                <div className="content">
                    <video src={item.extData?.videoPath} poster={item.extData?.picturePath} controls></video>
                </div>
            </div>
        case "tags":
            return <div key={item.mediaId} className="media">
                <TitleRender/>
                <div className={`content tags`}>
                    {
                        item.extData.tags?.map((tag: any) => {
                            return !!tag.id ? <Link to={`/detail/${tag.id}`} className="tag" key={tag.id}
                                                    state={{title: tag.name, back: window.location.pathname}}>
                                {tag.name}
                            </Link> : <div className="tag" key={tag.id}>{tag.name}</div>
                        })
                    }
                </div>
            </div>
        case "title":
            return <div key={item.mediaId} className="media title">
                <TitleRender noAudio/>
            </div>
        case "poetry":
            return <div key={item.mediaId} className="media poetry">
                <TitleRender/>
                <div className="content">
                    <pre>
                        {item.extData.description}
                    </pre>
                    <audio src={item.extData.audio?.url} controls></audio>
                </div>
            </div>

        case "slide":
            return <div key={item.mediaId} className="media slide">
                <TitleRender/>
                <div className="content" >
                    <Swiper autoplay>
                        {
                            item.extData?.pictures?.map((item: any, i: number) => <Swiper.Item key={item.picturePath}>
                                <Image lazy src={item.picturePath} onClick={() => {
                                    ImageViewer.show({image: item.picturePath})
                                }}></Image>
                            </Swiper.Item>)
                        }
                    </Swiper>
                </div>
            </div>

        case "multi_picture":
            return <div key={item.mediaId} className="media multi_picture">
                <TitleRender/>
                <div className="content">
                    <img src={item.extData.pictures[0]?.picturePath} style={{
                        opacity: 1 - multiPictureSliderValue / 50
                    }}/>
                    <img src={item.extData.pictures[1]?.picturePath} style={{
                        opacity: 2 - multiPictureSliderValue / 50
                    }}/>
                    <img src={item.extData.pictures[2]?.picturePath} style={{
                        opacity: 3 - multiPictureSliderValue / 50
                    }}/>

                </div>
                <div style={{paddingRight: 10}}>
                    <Slider max={100} min={0} onChange={(v) => setMultiPictureSliderValue(v)}/>
                </div>

            </div>


        default:
            return <div key={item.mediaId} className="media uknown">
                <TitleRender/>
                <div className="content">
                    <h1>？ 实现漏了</h1>
                    <div>
                        {JSON.stringify(item)}
                    </div>
                </div>
            </div>
    }
}