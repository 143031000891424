import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, InfiniteScroll } from "antd-mobile";
import { CloseOutline, RightOutline } from "antd-mobile-icons";
import { useDispatch, useSelector } from "react-redux";
import filtedTotalNumber from "../functions/filtedTotalNumber";
import { selectCacheData, selectJsonRequest, selectUser, setCacheData } from "../state/global";
import funcFilterSelected from "../functions/funcFilterSelected";
import funcFilterSelectedRemove from "../functions/funcFilterSelectedRemove";
import "./Cuisine.css"
import { Link } from "react-router-dom";
import ToTop from "../components/ToTop";
import SearchBySystem from "../module.search/SearchBySystem";
import OpenAppBanner from "../components/OpenAppBanner";
import OpenAppButton from "../components/OpenAppButton";
import PageEnter from "../functions/umeng/pageEnter";
import CustomImage from "../components/CustomImage";
import CustomLoading from "../components/CustomLoading";

export function Cuisine(params: any) {
    // 正经数据缓存key
    const dataCacheKey = `/cuisine/`
    // 筛选项数据缓存key
    const filterCacheKey = "/cuisine/filter"
    // 缓存数据
    const cacheData = useSelector(selectCacheData)
    const dispatch = useDispatch()

    const [fullScreen, setFullScreen] = useState(false)
    // 列表数据
    const [data, setData]: any = useState([])
    // 分页数据
    const [filterData, setFilterData]: any = useState(cacheData[filterCacheKey] || [])
    // 分页数据
    const [dataPage, setDataPage]: any = useState(1)
    // 分页数据
    const [hasMore, setHasMore]: any = useState(false)
    // 分页数据
    const [dataPageSize, setDataPageSize]: any = useState(30)
    // 筛选后的总数量
    const [filtedTotalCount, setFiltedTotalCount]: any = useState(0)
    // 选择的筛选项目
    const [filterSelected, setFilterSelected]: any = useState([])
    // json请求客户端
    const JsonRequest = useSelector(selectJsonRequest)
    // 当前登录的用户
    const user = useSelector(selectUser)

    const [loading, setLoading]: any = useState(false)

    // 筛选器的引用
    const filterRef: any = useRef()

    useEffect(() => {
        // 记录数据
        PageEnter("PageModuleListEnter", "药膳", { path: window.location.pathname, userId: user?.id })
    }, [])

    useEffect(() => {
        reloadFilter()
            .then((data) => {
                dispatch(setCacheData({ key: filterCacheKey, value: data }))
                reloadData(filterSelected, [], dataPage, dataPageSize)
            })
        reloadTotalNum()
    }, [filterSelected])

    // 加载数据
    const reloadData = async (filterSeleted: Array<string> | null, origins: Array<any>, page: number, size: number) => {
        setDataPage(page)
        setDataPageSize(size)

        let _filterSeleted: any = filterSeleted || []
        // 如果没有选择筛选项，则删掉
        if (_filterSeleted.length === 0)
            _filterSeleted = null

        setLoading(true)
        const { data: { list, pageSize } } = await JsonRequest.get("/v1/contents/paged", {
            systemCode: "cuisine",
            pageNumber: page,
            pageSize: size,
            cateIds: _filterSeleted?.map(({ id }: any) => id)
        })
        setLoading(false)
        setData(origins.concat(list))
        setHasMore(list.length >= pageSize)
    }

    // 加载筛选项
    const reloadFilter = async () => {
        if (filterData.length > 0) return filterData
        const { data } = await JsonRequest.get("/v1/content/cates/group-trees", { systemCode: "cuisine" })
        setFilterData(data)
        return data
    }


    const reloadTotalNum = async () => {
        setFiltedTotalCount(await filtedTotalNumber(JsonRequest, "cuisine", filterSelected))
    }

    // 筛选过的筛选项视图
    const viewFilterSeleted = () => {
        return filterSelected?.map((item: any) => <div className="item" onClick={() => handleFilterRemove(item)}
            key={item.id}>{item.cateName} <CloseOutline /></div>)
    }

    // 单个项目的样式
    const viewItem = (item: any) => {
        return <Link to={`/cuisine/detail/${item.id}`} key={item.id} state={{ title: item.name, back: window.location.pathname }} className="item">
            <div className="avatar">
                <CustomImage src={item.icon} lazy />
            </div>
            <div className="content">
                <div className="title fts14">{item.name}</div>
                <div className="summary">{item.description}</div>
            </div>
            <div className="link-icon">
                <RightOutline />
            </div>
        </Link>
    }

    // 筛选项选择
    const handleFilterSelect = (item: any) => {
        if (!filterSelected.find(({id}: any) => id === item.id)) {
            // 记录点击筛选
            window.aplus_queue.push({
                action: 'aplus.record',
                arguments: ["FilterSelectClick", "CLK", {type: "cuisine", value: item.cateName}]
            });
        }
        setDataPage(1)
        funcFilterSelected(item, filterSelected, setFilterSelected)
    }
    // 筛选项选择
    const handleFilterRemove = (item: any) => {
        setDataPage(1)
        funcFilterSelectedRemove(item, filterSelected, setFilterSelected)
    }

    // 筛选提交
    const handleFilterSubmit = () => {
        filterRef?.current?.close()
    }

    // 筛选重制
    const handleFilterReset = () => {
        setDataPage(1)
        setFilterSelected([])
    }

    return <section className="page cuisine-index">
        <section className="search">
            <SearchBySystem placeholder="搜索药膳" systemCode='cuisine' onFullScreen={() => setFullScreen(true)}
                onBack={() => setFullScreen(false)} />
        </section>

        <section className={`filter ${fullScreen ? "hide" : ""}`}>
            <Dropdown ref={filterRef} closeOnClickAway>
                {
                    filterData?.map(({ id, groupName, trees }: any) => {
                        return <Dropdown.Item className="dropdown" key={id} title={groupName} highlight={true}>
                            <div style={{ padding: 12 }} className="hern tags">
                                {
                                    trees?.map((item: any) => <div onClick={() => handleFilterSelect(item)}
                                        key={item.id}
                                        className={`tag ${filterSelected?.map(({ id }: any) => id).indexOf(item.id) > -1 && "selected"}`}>{item.cateName}</div>)
                                }
                            </div>

                            <div className="receipe buttons">
                                <Button className="reset" onClick={handleFilterReset}>重置</Button>
                                <Button className="submit"
                                    onClick={handleFilterSubmit}>查看{filtedTotalCount}种药膳</Button>
                            </div>
                        </Dropdown.Item>
                    })
                }
            </Dropdown>

            <div className="selected-filter">
                {viewFilterSeleted()}
            </div>
        </section>
        <section className={`content ${fullScreen ? "hide" : ""}`}>
            {loading && data.length === 0 ? <CustomLoading width={80} /> :
                <section className="list">
                    <section className="block cate">
                        <section className="items">
                            {data?.map(viewItem)}
                            <InfiniteScroll
                                loadMore={async () => await reloadData(filterSelected, data, dataPage + 1, dataPageSize)}
                                hasMore={hasMore} />
                        </section>
                    </section>
                </section>
            }
        </section>

        <ToTop />


        <OpenAppBanner hide={fullScreen} app="zj" title="中济经络穴位" summary="中医经络穴位针灸的3D模型活地图">
            <OpenAppButton url="https://www.lighttcm.com/invite" appid="wx96950494c868cde4"
                iOSMarketUrl="itms-apps://itunes.apple.com/app/id1554030035"
                androidMarketUrl="market://details?id=com.mc.acupoint_3d" />
        </OpenAppBanner>
    </section>
}