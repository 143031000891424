import {useState} from "react"
import {Button, Input} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import SearchBody from "./SearchBody";
import "./Search.css"
import imgSearchIcon from "../images/search/search@2x.webp";
import imgBackIcon from "../images/search/back@2x.webp"

export default function Search({
                                   model = "home",
                                   fullScreen = false,
                                   placeholder = "搜索穴位、中草药、方剂等",
                                   onFullScreen = () => null,
                                   onBack = () => null,
                                   defaultSearch = "",
                               }: any) {
    const [_fullScreen, setFullScreen]: any = useState(fullScreen)
    const [searchQuery, setSearchQuery]: any = useState(defaultSearch)
    const [animate, setAnimate]: any = useState(fullScreen)
    const navigate = useNavigate();

    const handleQueryClick = () => {
        setFullScreen(true)
        onFullScreen()
        setAnimate(true)

        // 点击搜索栏
        window.aplus_queue.push({
            action: 'aplus.record',
            arguments: ["SearchBarClick", "CLK", {}]
        });
    }

    const handleBack = () => {

        if (_fullScreen) {
            setSearchQuery('')
            setFullScreen(false)
            setAnimate(true)
            onBack()
            // 点击搜索栏
            window.aplus_queue.push({
                action: 'aplus.record',
                arguments: ["SearchBarClickBack", "CLK", {type: "CloseFullScreen"}]
            });
        } else {
            navigate(-1)
            // 点击搜索栏
            window.aplus_queue.push({
                action: 'aplus.record',
                arguments: ["SearchBarClickBack", "CLK", {type: "PageBack"}]
            });
        }
    }

    const onSearch = (keyword: string) => {
        setSearchQuery(keyword)
        setFullScreen(false)
        setAnimate(true)
        onBack()
        navigate(`/search-result/${keyword}`)
    }

    const barView = () => {
        switch (model) {
            case "home":
                return <>
                    <div className='back' onClick={handleBack}>
                        <img src={imgBackIcon} alt="back icon"/>
                    </div>
                    <label className="search-input">
                        <img src={imgSearchIcon} alt="search icon"></img>
                        <Input
                            style={_fullScreen || searchQuery ? undefined : {width: `${placeholder.length * 1.3}em`}}
                            placeholder={placeholder} value={searchQuery} onClick={handleQueryClick}
                            onChange={setSearchQuery} clearable
                            onEnterPress={() => onSearch(searchQuery)}/>
                    </label>
                    <div className="search-button">
                        <Button color="primary" fill="none" onClick={() => onSearch(searchQuery)}>搜索</Button>
                    </div>
                </>

            default:
                break;
        }
    }

    return <div className={`component search-bar ${_fullScreen ? "fullscreen" : "bar"} ${animate && "animate"}`}>
        <div className="banner">
            {barView()}
        </div>
        {_fullScreen && <SearchBody onSearch={onSearch} fullScreen={_fullScreen} searchQuery={searchQuery}/>}
    </div>
}