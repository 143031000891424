import imgNoSearch from "../images/home/search.webp";
import {CloseOutline, SearchOutline} from "antd-mobile-icons";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {selectJsonRequest, selectUser} from "../state/global";
import imgDelIcon from "../images/search/del@2x.webp"
import imgUpIcon from '../images/search/his_up@2x.webp'
import imgDownIcon from '../images/search/his_down@2x.webp'

export default function SearchBody({searchQuery, systemCode, fullScreen, onSearch}: any) {
    const JsonRequest = useSelector(selectJsonRequest)
    const user = useSelector(selectUser)
    const [history, setHistory]: any = useState([])
    const [hots, setHots]: any = useState([])
    const [suggests, setSuggests]: any = useState([])
    const [cleanMode, setCleanMode] = useState(false)
    const tagsRef = useRef<HTMLDivElement>(null)
    const [moreTagsShow, setMoreTagsShow] = useState(0)
    // 0 不显示  1查看更多 2隐藏更多


    useEffect(() => {
        if (!fullScreen) return;
        getSearchHots()
        getSearchHistory()
    }, [fullScreen]);

    useEffect(() => {
        getSuggests();
    }, [searchQuery]);

    useEffect(() => {
        if (tagsRef.current) {
            const height = tagsRef.current.scrollHeight;
            setMoreTagsShow(height > 30 ? 1 : 0)
        }
    }, [history]);

    const getSuggests = async () => {
        if (searchQuery.trimEnd().length === 0) return
        const {data} = await JsonRequest.get('/v1/elasticsearch/suggest',
            {keyword: searchQuery.trimEnd(), systemCode, size: 10})
        setSuggests(data)
    }

    const getSearchHistory = async () => {
        const {data} = await JsonRequest.get("/v1/search/history", {userId: user?.id, size: 50})
        setHistory(data)
    }

    const getSearchHots = async () => {
        const {data} = await JsonRequest.get("/v1/search/hots", {})
        setHots(data)
    }

    const clearHistory = async (tag?: string) => {
        if (!tag) {
            setCleanMode(false)
            await JsonRequest.delete('/v1/search', {userId: user?.id})
            setHistory([])
            return
        }
        await JsonRequest.delete('/v1/search', {userId: user?.id, term: tag})
        setHistory(history.filter(((item: string) => item !== tag)))
    }

    const handleHotClick = (searchTerm: string) => {
        onSearch(searchTerm)
        // 5.搜索点击热门事件，如热门-保命丸
        window.aplus_queue.push({
            action: 'aplus.record',
            arguments: ["SearchHotClick", "CLK", {value: searchTerm}]
        });
    }

    if (searchQuery.length > 0)
        return <div className='search-suggest scroll-content'>
            <div className='suggest-list'>
                {suggests?.map?.((item: any) =>
                    <div onClick={() => onSearch(item)} className='suggest-item' key={item}>
                        <SearchOutline/>
                        <span>{item}</span>
                    </div>)}
            </div>
        </div>

    if (searchQuery.length > 0)
        return <div className='search-suggest scroll-content'>
            {suggests.length === 0 ? <div className='no-search'>
                <img src={imgNoSearch} width={128}/>
                <span>没有找到相关内容，试试其他关键字？</span>
            </div> : <div className='suggest-list'>
                {suggests?.map?.((item: any) =>
                    <div onClick={() => onSearch(item)} className='suggest-item' key={item}>
                        <SearchOutline/>
                        <span>{item}</span>
                    </div>)}
            </div>}
        </div>

    return <div className="content">
        {
            !!history?.length && <div className="history">
                <div className="header">
                    <div>历史</div>
                    {
                        !cleanMode ? <img src={imgDelIcon} onClick={() => setCleanMode(!cleanMode)} alt="del"/> :
                            <div className="actions">
                                <div onClick={() => clearHistory()}>全部删除</div>
                                <div onClick={() => setCleanMode(false)}>完成</div>
                            </div>
                    }
                </div>
                <div className="search-tags history-tags" ref={tagsRef}
                     style={cleanMode || moreTagsShow === 2 ? {overflow: "visible", height: 'auto'} : {
                         width: 'calc(100% - 40px)',
                         height: '30px',
                         overflow: 'hidden'
                     }}>
                    {
                        history.map((e: any) =>
                            <div onClick={() => cleanMode ? clearHistory(e) : onSearch(e)} key={e} className="tag">
                                {e}
                                {cleanMode && <CloseOutline/>}
                            </div>)
                    }
                    {!cleanMode && moreTagsShow === 2 &&
                        <img className="up-icon" src={imgUpIcon} alt="a little" onClick={() => setMoreTagsShow(1)}/>}
                </div>
                {
                    !cleanMode && moreTagsShow === 1 &&
                    <img className="down-icon" src={imgDownIcon} alt="more" onClick={() => setMoreTagsShow(2)}/>
                }
            </div>
        }
        <div className="hot-search">
            <div className="title">热门</div>

            <div className="search-tags">
                {hots?.map((e: any) =>
                    <div onClick={() => handleHotClick(e.searchTerm)} key={e.searchTerm}
                         className="tag">{e.searchTerm}</div>)}
            </div>
        </div>
    </div>
}