import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    selectCacheData,
    selectInWeChat,
    selectJsonRequest,
    selectNextTrackCode,
    selectUser,
    setCacheData
} from "../state/global"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { Tabs, Image, Skeleton, ImageViewer } from "antd-mobile"
import { useThrottleFn } from 'ahooks'
import "./Detail.css"
import Media from "./components/Media"
import DetailBox from "../components/detail-box/DetailBox";
import PageEnter from "../functions/umeng/pageEnter"
import CustomLoading from "../components/CustomLoading";
import { updateShareData } from "../functions/umeng/handleShareData";

const tabHeight = 101
export default function Detail(params: any) {
    // 缓存数据
    const cacheData = useSelector(selectCacheData)
    const inWeChat = useSelector(selectInWeChat)
    const dispatch = useDispatch()
    let { state } = useLocation();
    let { id, type } = useParams()
    // 正经数据缓存key
    const dataCacheKey = `/detail/${id}`

    // 当前激活的组
    const [activeKey, setActiveKey] = useState()
    const [loading, setLoading] = useState(true)
    // json请求客户端
    const JsonRequest = useSelector(selectJsonRequest)
    const user = useSelector(selectUser)
    const nextTrackCode = useSelector(selectNextTrackCode)
    const [searchParams] = useSearchParams();
    const [hideMediaData,setHideMediaData]:any = useState(undefined)
    // 获取微信的响应
    const weixinCode = searchParams.get("code")

    // 分页数据
    const [data, setData]: any = useState(cacheData[dataCacheKey] || {})

    useEffect(() => {
        const content = document.querySelector(".page .content")
        content?.addEventListener('scroll', handleScroll)
        content?.scrollTo({ top: 0, behavior: "smooth" })
        reloadData()
    }, [id])

    // 监听滑动
    const { run: handleScroll } = useThrottleFn(
        () => {
            let currentKey = data.medias[0]?.mediaId
            for (const item of data.medias) {
                const element = document.getElementById(`anchor-${item?.mediaId}`)
                if (!element) continue
                const rect = element.getBoundingClientRect()
                if (rect.top <= tabHeight) {
                    currentKey = item?.mediaId
                } else {
                    break
                }
            }

            setActiveKey(currentKey)
        },
        {
            leading: true,
            trailing: true,
            wait: 100,
        }
    )

    // 加载数据
    const reloadData = () => {
        setLoading(true)
        JsonRequest.get("/v1/contents/medias", { type: "content", id: id, userId: user?.id })
            .then(({data}: any) => {
                // 删除一半内容不显示，获取删除里的第一个渲染渐变隐藏
                if (data?.medias) {
                    if (data.medias?.[0]?.mediaType === "group" && data.medias.length <= 1) {
                        //  二维
                        setHideMediaData(data.medias[0].sons.splice(Math.ceil(data.medias[0].sons.length / 2) || 1, data.medias[0].sons.length).slice(0, 1)[0])
                    } else {
                        setHideMediaData(data.medias.splice(Math.ceil(data.medias.length / 2) || 1, data.medias.length).slice(0, 1)[0])
                    }
                }
                setData(data)
                setLoading(false)
                dispatch(setCacheData({ key: dataCacheKey, value: data }))
                setActiveKey(data.medias[0]?.mediaId)

                // 记录数据
                PageEnter("PageDetailEnter", data?.contentName, { path: window.location.pathname, id, userId: user?.id })

                const shareData = {
                    title: `${data?.contentName} · 知源百科`, // 分享标题
                    desc: `${data?.description || ""}\n浏览量：${data?.viewNum}`, // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: data?.icon || 'https://zhiyuan-knowledage-foundation.oss-cn-shenzhen.aliyuncs.com/logo_zhiyuan_wiki.jpg', // 分享图标\
                }
                if (inWeChat) {
                    updateShareData(shareData, nextTrackCode, id, type)
                }
            })
    }

    return <DetailBox title={state?.title || data.contentName} type={type}>
        {data.medias?.length > 1 &&
            <section className="navigator">
                <Tabs
                    activeKey={activeKey}
                    onChange={key => {
                        const top = document.getElementById(`anchor-${key}`)?.offsetTop
                        document.querySelector(".page .content")?.scrollTo({
                            top: top || 0,
                            behavior: "smooth"
                        })
                    }}>
                    {
                        data.medias?.map((item: any) => (
                            <Tabs.Tab title={item.mediaName} key={item.mediaId} />
                        ))
                    }
                </Tabs>
            </section>
        }
        <section className="content">
            {data?.icon && <Image lazy className="img" src={data?.icon} placeholder={<CustomLoading height={200} />}
                onClick={() => {
                    ImageViewer.show({ image: data?.icon })
                }}></Image>}
            {
                !data?.medias && <>
                    <div className="group">
                        <Skeleton.Title />
                        <Skeleton.Paragraph />
                        <Skeleton.Title />
                        <Skeleton.Paragraph lineCount={5} animated />
                        <Skeleton.Title />
                        <Skeleton.Paragraph lineCount={5} animated />
                        <Skeleton.Title />
                        <Skeleton.Paragraph lineCount={5} animated />
                        <Skeleton.Title />
                        <Skeleton.Paragraph lineCount={5} animated />
                        <Skeleton.Title />
                        <Skeleton.Paragraph lineCount={5} animated />
                    </div>
                </>
            }
            {
                // 判断是否有分组数据，如果没有的话则添加一个大分组
                data?.medias?.length && (
                    data.medias?.[0]?.mediaType === "group" ?
                        [
                            data.medias.map((item: any) => {
                                return <Media
                                    key={item.mediaId}
                                    item={item}
                                    info={{id, type, userId: user.id}}
                                    sons={(hideMediaData && hideMediaData.mediaType !== "group") ? hideMediaData : undefined}
                                />
                            }),
                            hideMediaData?.mediaType === "group" && <Media
                                key={hideMediaData.mediaId}
                                item={hideMediaData}
                                info={{id, type, userId: user.id}}
                                group={true}
                            />
                        ] :
                        <div className="media group">
                            {
                                data.medias.map((item: any) => {
                                    return <Media
                                        key={item.mediaId}
                                        item={item}
                                        info={{id, type, userId: user.id}}
                                    />
                                })
                            }
                            {
                                hideMediaData && <div className="hide-mask">
                                    <Media
                                        key={hideMediaData.mediaId}
                                        item={hideMediaData}
                                        info={{id, type, userId: user.id}}
                                    />
                                </div>
                            }
                        </div>
                )
            }
        </section>
    </DetailBox>
}