import './App.css';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import Home from './Home';
import devtools from 'devtools-detector';
import { store } from './state/store';
import {
    selectInWeChat,
    selectJsonRequest,
    selectNextTrackCode,
    selectUser, setNextTrackCode,
    signIn,
    updateDevToolsOpened
} from "./state/global"
import { Receipe } from './module.receipe/Receipe';
import { Herb } from "./module.herb/Herb";
import { Food } from './module.food/Food';
import { Illness } from './module.illness/Illness';
import { Meridian } from './module.meridian/Meridian';
import { Cuisine } from './module.cuisine/Cuisine';
import { MeridianDongs } from './module.meridian.dongs/MeridianDongs';
import Detail from './module.detail/Detail';
import SearchResult from "./module.search/result/SearchResult";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Oppose from "./module.oppose/Oppose";
import Measure from "./module.measure/Measure";
import { getNextTrackCode, updateShareData } from "./functions/umeng/handleShareData";
import getWechatCodeUrl from './functions/getWechatCodeUrl';

declare global {
    interface Window {
        wx: any,
        aplus_queue: any,
        appid: string,
        WeixinJSBridge: any
    }
}

if (process.env.NODE_ENV === 'production') {
    devtools.addListener(
        (isOpen) => {
            console.log(isOpen)
            store.dispatch(updateDevToolsOpened(isOpen))
        }
    );

    // devtools.setDetectDelay();
    devtools.launch();
}


const shareData = {
    title: '知源百科 · 知源健康', // 分享标题
    desc: '我们汇聚了全面的中医百科知识，涵盖中药、方剂、经络穴位、疾病、食材、药膳等。一键查询，详细解析，无论您想了解什么中医内容，我们都能满足。', // 分享描述
    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: 'https://zhiyuan-knowledage-foundation.oss-cn-shenzhen.aliyuncs.com/logo_zhiyuan_wiki.jpg', // 分享图标
}

function App() {
    const dispatch = useDispatch();
    // 请求客户端
    const JsonRequest = useSelector(selectJsonRequest)
    // 当前登录的微信用户
    const user = useSelector(selectUser)
    const nextTrackCode = useSelector(selectNextTrackCode)
    // 是否在微信浏览器里
    const inWeChat = useSelector(selectInWeChat)
    const location = useLocation()
    const [searchParams] = useSearchParams();
    // 获取微信的响应
    const weixinCode = searchParams.get("code")

    useEffect(() => {
        window.aplus_queue.push({
            action: 'aplus.sendPV',
            arguments: [{ is_auto: false }, { user_id: user?.id, user_nickname: user?.nickName }]
        });

        if (inWeChat) {
            //是否指定用作计算umid的id类型，默认为cnaid，目前支持:
            //1. 微信和QQ: openid; 字节和百度 anonymousid; 支付宝 alipay_id
            //2. 微信、QQ、字节、百度平台的 unionid
            //3. 业务方自己生成的随机id uuid
            window.aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['aplus-idtype', 'openid'] //取值参考见附表1
            });

            // 如果用户完全没登录
            if (!user && !weixinCode) {
                window.location.replace(getWechatCodeUrl())
            } else {
                JsonRequest.get("/v1/wechat/signature", { url: window.location.href.split('#')[0] })
                    .then(({ data }: any) => {
                        window.wx.config({
                            ...data,
                            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
                            openTagList: ['wx-open-launch-app']
                        })
                    })
            }

            // 获取到微信code的时候
            if (!user && !!weixinCode) signInByWechatCode(weixinCode)
        }
    }, [])

    useEffect(() => {
        if (user && inWeChat && !location.pathname.includes('/detail')) {
            // shareData.link = getWechatCodeUrl()
            updateShareData(shareData, nextTrackCode)
        }
    }, [user, location.pathname])

    const signInByWechatCode = async (code: string) => {
        const { data } = await JsonRequest.post("/v1/wechat/login", { code })
        dispatch(signIn(data))
        // 获取分享给下个人的code
        const nextTrackCode = await getNextTrackCode(searchParams.get('trackCode') || '', data)
        dispatch(setNextTrackCode(nextTrackCode))
    }

    if (!!inWeChat && !user) {
        return <></>
    }

    return (
        <Routes>
            <Route path="/sign_in" element={<Home />}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route path="/receipe" element={<Receipe />}></Route>
            <Route path="/herb" element={<Herb />}></Route>
            <Route path="/food" element={<Food />}></Route>
            <Route path="/illness" element={<Illness />}></Route>
            <Route path="/meridian/:meridianType" element={<Meridian />}></Route>
            <Route path='/cuisine' element={<Cuisine />}></Route>
            <Route path='/meridian-dongs' element={<MeridianDongs />}></Route>
            <Route path='/detail/:id' element={<Detail />}></Route>
            <Route path='/:type/detail/:id' element={<Detail />}></Route>
            <Route path='/search-result/:keyword' element={<SearchResult />}></Route>
            <Route path='/oppose' element={<Oppose />}></Route>
            <Route path='/measure' element={<Measure />}></Route>

            <Route path="*" element={<Home />} />
        </Routes>
    );
}

export default App;