import {useState} from "react"

import "./SearchBySystem.css"
import {Button, Image, Input} from "antd-mobile";
import {Link, useNavigate} from "react-router-dom";
import SearchBody from "./SearchBody";
import iconHome from "../images/home.jpg";
import imgBackIcon from "../images/search/back@2x.webp";
import imgSearchIcon from "../images/search/search@2x.webp";

export default function SearchBySystem({
                                           model = "home",
                                           fullScreen = false,
                                           placeholder = "搜索穴位、中草药、方剂等",
                                           onFullScreen = () => null,
                                           onBack = () => null,
                                           defaultSearch = "",
                                           replace = false,
                                           systemCode,
                                       }: any) {
    const [_fullScreen, setFullScreen]: any = useState(fullScreen)
    const [searchQuery, setSearchQuery]: any = useState(defaultSearch)
    const [animate, setAnimate]: any = useState(false)
    const navigate = useNavigate();

    const handleQueryClick = () => {
        setFullScreen(true)
        setAnimate(true)
        onFullScreen()
    }

    const handleBack = () => {
        if (_fullScreen) {
            setFullScreen(false)
            setAnimate(true)
            onBack()
        } else {
            navigate(-1)
        }
    }

    const onSearch = (keyword: string) => {
        setSearchQuery(keyword)
        setFullScreen(false)
        setAnimate(true)
        onBack()

        navigate(`/search-result/${keyword}`, {replace, state: {systemCode}})
    }

    const barView = () => {
        switch (model) {
            case "home":
                return <>
                    <div className='back'>
                        {
                            window.history.length > 1 ?
                                <img src={imgBackIcon} onClick={handleBack} alt="back icon"/>
                                :
                                <Link to="/"><Image src={iconHome} className="img"></Image></Link>
                        }
                    </div>
                    <label className={`search-input ${searchQuery.length > 0 && 'search-input-left'}`}>
                        <img src={imgSearchIcon} alt="search icon"></img>
                        <Input
                            style={!searchQuery ? {width: `${placeholder.length * 1.3}em`} : undefined}
                            placeholder={placeholder} value={searchQuery} onClick={handleQueryClick}
                            onChange={val => setSearchQuery(val)} clearable
                            onEnterPress={() => onSearch(searchQuery)}/>
                    </label>
                    <div className="search-button">
                        <Button color="primary" fill="none" onClick={() => onSearch(searchQuery)}>搜索</Button>
                    </div>
                </>

            default:
                break;
        }
    }

    return <div className={`component search-bar-system ${_fullScreen ? "fullscreen" : "bar"} ${animate && "animate"}`}>
        <div className="banner">
            {barView()}
        </div>
        {_fullScreen && <SearchBody onSearch={onSearch} systemCode={systemCode} fullScreen={_fullScreen}
                                    searchQuery={searchQuery}/>}
    </div>
}