import "./OpenAppBanner.css"

export default function OpenAppBanner({
    app,
    title,
    summary,
    children,
    hide = false }: any) {
    return hide ? <></> : <div className={`open-app-banner ${app}`}>
        <div className="logo">
        </div>
        <div className="info">
            <div className="title">{title}</div>
            <div className="summary">{summary}</div>
        </div>
        <div className="button">
            {children}
        </div>
    </div>
}