import {Image} from 'antd-mobile'
import CustomLoading from "./CustomLoading";


export default function CustomImage({lazy, ...others}: any) {

    const Loading = () => <div {...others}><CustomLoading/></div>

    return <Image {...others} lazy={lazy} placeholder={<Loading/>}/>

}