export default class Tools {
    static copy(content: string) {
        // content为要复制的内容
        // 创建元素用于复制
        const ele = document.createElement('input')
        // 设置元素内容
        ele.setAttribute('value', content)
        // 将元素插入页面进行调用
        document.body.appendChild(ele)
        // 复制内容
        ele.select()
        // 将内容复制到剪贴板
        document.execCommand('copy')
        // 删除创建元素
        document.body.removeChild(ele)
    }
    
    static isIOS() {
        const ua = typeof window === 'object' ? window.navigator.userAgent : '';

        let _isIOS = -1;
        if (_isIOS === -1) {
            _isIOS = /iPhone|iPod|iPad/i.test(ua) ? 1 : 0;
        }
        return _isIOS === 1;
    }

    static isAndroid() {
        let _isAndroid = -1;
        const ua = typeof window === 'object' ? window.navigator.userAgent : '';
        if (_isAndroid === -1) {
            _isAndroid = /Android/i.test(ua) ? 1 : 0;
        }
        return _isAndroid === 1;
    }

    static isWechat() {
        const ua = typeof window === 'object' ? window.navigator.userAgent : '';
        return !!ua.match(/MicroMessenger/i)
    }

    static convertToBase64(file: File, size = undefined) {
        let reader = new FileReader();
        if (file) {
            return new Promise((resolve, reject) => {
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    resolve(reader.result);
                }
            })
        }
    }

    /**
     * 删除对象中空属性
     * @param obj 对象
     * @returns {*} 结果
     */
    static deleteEmptyOption(obj: any): any {
        if (!(obj instanceof Object)) {
            return;
        }
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === '' || obj[key] === undefined))
                delete obj[key]
        }
        return obj;
    }
}