import {forwardRef, Ref, useEffect, useImperativeHandle, useState} from "react"
import "./Filter.css"
import {Button, Toast} from "antd-mobile"
import {useSelector} from "react-redux"
import {selectJsonRequest} from "../../state/global"
import filtedTotalNumber from "../../functions/filtedTotalNumber"

export default forwardRef(function MeridianFilter({
                                                      filterCurrentGroup = null,
                                                      active = false,
                                                      onSubmit = () => null,
                                                      onClose = () => null,
                                                      filterData,
                                                      meridianType
                                                  }: any, ref: Ref<any>) {

    const [closed, setClosed]: any = useState(null)

    const JsonRequest = useSelector(selectJsonRequest)
    const [currentGroup, setCurrentGroup]: any = useState(filterCurrentGroup)
    const [groupSelected, setGroupSelected]: any = useState({})
    // 筛选后的总数量
    const [filtedTotalCount, setFiltedTotalCount]: any = useState(0)

    useEffect(() => {
        filtedTotalNumber(JsonRequest, "acupoint_ancestor", getFilterSelectedFlat(), meridianType)
            .then(setFiltedTotalCount)
    }, [groupSelected])


    useEffect(() => {
        if (active)
            setCurrentGroup(filterCurrentGroup)
    }, [active])

    useImperativeHandle(ref, () => ({
        handleSelectFilter
    }))

    const handleClose = () => {
        onClose()
        onSubmit(getFilterSelectedFlat())
        setClosed(true)
        setTimeout(() => {
            setClosed(false)
            setCurrentGroup(null)
        }, 300)

    }

    // 点击选择
    const handleSelectFilter = (groupId: string, item: any) => {
        let group = groupSelected[groupId] || []
        const index = group.map(({id}: any) => id).indexOf(item.id)
        if (index > -1) {
            group.splice(index, 1)
        } else {
            group = [...group, item]
            // 记录点击筛选
            window.aplus_queue.push({
                action: 'aplus.record',
                arguments: ["FilterSelectClick", "CLK", {type: meridianType, value: item.cateName}]
            });
        }
        setGroupSelected({...groupSelected, [groupId]: group})
    }

    // 点击组里的全部
    const handleSelectFilterAll = (groupId: any) => {
        let group = groupSelected
        group[groupId] = []
        setGroupSelected({...group})
    }

    const handleReset = () => {
        if (Object.keys(groupSelected).length === 0) {
            Toast.show({content: '重置成功'})
            return
        }
        setGroupSelected({})
    }

    // 判断项是否被筛选
    const booleanSelected = (groupId: any, item: any): boolean => {
        return groupSelected[groupId]?.map(({id}: any) => id).indexOf(item.id) > -1
    }

    // 由于选择是按组的， 这里铺平成数组
    const getFilterSelectedFlat = (): Array<{ id: string }> => {
        return Object.keys(groupSelected).reduce((selectedIds: Array<{ id: string }>, groupId: any) => {
            return [...selectedIds, ...groupSelected[groupId]]
        }, [])
    }

    return <div className={`meridian component filter ${active && "active"} ${closed && "closed"}`}>
        <div className="cover" onClick={handleClose}>
        </div>

        <div className="pannel">
            <div className="title">
                筛选
            </div>

            <div className="options">
                <div className="sider">
                    {
                        filterData?.map((item: any) => {

                            return <div key={item.id} onClick={() => setCurrentGroup(item.id)}
                                        className={`group ${currentGroup === item.id && "active"}`}>
                                <span className="title">{item.groupName}</span>
                                {
                                    !!groupSelected[item.id]?.length && <span className="selected-count">
                                        {groupSelected[item.id].length}
                                    </span>
                                }

                            </div>
                        })
                    }
                </div>
                <div className="content">
                    <div onClick={() => handleSelectFilterAll(currentGroup)}
                         className={`${(!groupSelected[currentGroup] || groupSelected[currentGroup].length == 0) && "active"} option`}>全部
                    </div>
                    {
                        filterData.find((item: any) => item.id === currentGroup)?.trees?.map((item: any) =>
                            <div key={item.id} onClick={() => handleSelectFilter(currentGroup, item)}
                                 className={`option ${booleanSelected(currentGroup, item) && "active"}`}>{item.cateName}</div>
                        )
                    }
                </div>
            </div>
            <div className="buttons">
                <Button className="reset" onClick={handleReset}>重置</Button>
                <Button className="submit" onClick={handleClose}>查看{filtedTotalCount || 0}种穴位</Button>
            </div>
        </div>
    </div>
})
