import Search from "../../module.search/Search";
import { Button, Image } from "antd-mobile";
import { Link, useLocation, useNavigate } from "react-router-dom";
import iconHome from "../../images/home.jpg";
import imgSearchIcon from "../../images/detail/search@2x.webp"
import imgBackIcon from "../../images/detail/back@2x.webp"
import { useState } from "react";
import OpenAppBanner from "../OpenAppBanner";
import OpenAppButton from "../OpenAppButton";
import "./DetailBox.css";

export default function DetailBox({ title, type, children }: any) {
    const [fullScreen, setFullScreen]: any = useState(false)
    let { state } = useLocation();

    const viewOpenAppBanner = () => {
        switch (type) {
            case "receipe":
                return <OpenAppBanner hide={fullScreen} app="zy" title="知源中医"
                    summary="古籍·中医·方剂大全">
                    <a href="https://new.mobile.zhiyuanzhongyi.com/#/zyIntro" className="button">打开APP</a>
                </OpenAppBanner>

            default:
                return <OpenAppBanner hide={fullScreen} app="zj" title="中济经络穴位"
                    summary="中医经络穴位针灸的3D模型活地图">
                    <OpenAppButton url="https://www.lighttcm.com/invite" appid="wx96950494c868cde4"
                        iOSMarketUrl="itms-apps://itunes.apple.com/app/id1554030035"
                        androidMarketUrl="market://details?id=com.mc.acupoint_3d" />
                </OpenAppBanner>
        }
    }

    return <section className={`page detail ${fullScreen && "on-search"}`}>
        <section className="header">
            {
                fullScreen ? <Search fullScreen={true} onFullScreen={() => setFullScreen(true)}
                    onBack={() => setFullScreen(false)} /> :
                    <>
                        <span className="icon home">
                            {
                                !!state?.back ?
                                    <Link to={state?.back} replace><Image src={imgBackIcon} className="img"></Image></Link> :
                                    <Link to="/" replace><Image src={iconHome} className="img"></Image></Link>
                            }
                        </span>
                        <span className="title">{title}</span>
                        <span className="icon search">
                            <Button fill="none" onClick={() => setFullScreen(true)}>
                                <Image className="img" src={imgSearchIcon} alt="搜索" />
                            </Button>
                        </span>
                    </>
            }
        </section>
        {children}
        {
            !fullScreen && viewOpenAppBanner()
        }
    </section>
}