import { useEffect, useState } from "react";
import Search from "../module.search/Search";
import { Tabs } from "antd-mobile";
import { useThrottleFn } from 'ahooks'
import "./MeridianDongs.css"
import { useSelector } from "react-redux";
import { selectJsonRequest, selectUser } from "../state/global";
import { Link } from "react-router-dom";
import ToTop from "../components/ToTop";
import SearchBySystem from "../module.search/SearchBySystem";
import OpenAppBanner from "../components/OpenAppBanner";
import OpenAppButton from "../components/OpenAppButton";
import PageEnter from "../functions/umeng/pageEnter";

const tabHeight = 109

export function MeridianDongs(params: any) {
    const [fullScreen, setFullScreen] = useState(false)

    const [activeKey, setActiveKey] = useState('手指部')

    // 列表数据
    const [data, setData]: any = useState([])
    // 选择的筛选项目
    const [filterSeleted, setFilterSeleted]: any = useState([])
    // json请求客户端
    const JsonRequest = useSelector(selectJsonRequest)

    const { run: handleScroll } = useThrottleFn(
        () => {
            let currentKey = data[0].alias
            for (const item of data) {
                const element = document.getElementById(`anchor-${item.alias}`)
                if (!element) continue
                const rect = element.getBoundingClientRect()
                if (rect.top <= tabHeight) {
                    currentKey = item.alias
                } else {
                    break
                }
            }
            setActiveKey(currentKey)
        },
        {
            leading: true,
            trailing: true,
            wait: 100,
        }
    )

    // 当前登录的用户
    const user = useSelector(selectUser)

    useEffect(() => {
        // 记录数据
        PageEnter("PageModuleListEnter", "董氏奇穴", { path: window.location.pathname, userId: user?.id })
    }, [])


    useEffect(() => {
        const content = document.querySelector(".page .content")

        content?.addEventListener('scroll', handleScroll)
        reloadData(filterSeleted)

        return () => {
            content?.removeEventListener('scroll', handleScroll)
        }


    }, [filterSeleted])

    // 加载数据
    const reloadData = (filterSeleted: Array<string> | null) => {
        let _filterSeleted: any = filterSeleted || []
        // 如果没有选择筛选项，则删掉
        if (_filterSeleted.length === 0)
            _filterSeleted = null
        JsonRequest.get("/v1/contents/meridian-points", { type: 4, cateIds: _filterSeleted?.map(({ id }: any) => id) })
            .then(({ data }) => setData(data))
    }

    // 单个项目的样式
    const viewItem = (item: any) => {
        if ("meridianName" in item) {
            return <section className="block cate" key={item.id}>
                <span className="title no-sticky" id={`anchor-${item.alias}`}>{item.meridianName}</span>
                <div className="tags">
                    {item.acupoints?.map(viewItem)}
                </div>
            </section>
        } else {
            return <Link key={item.id} id={item.id} state={{ title: item.acupointName, back: window.location.pathname }} to={`/meridian/detail/${item.id}`}
                className="tag">{item.acupointName}</Link>
        }
    }

    return <section className="page acupoint_dongs-index">
        <section className="search">
            <SearchBySystem placeholder="搜索董氏奇穴" systemCode='acupoint_dongs'
                onFullScreen={() => setFullScreen(true)} onBack={() => setFullScreen(false)} />
        </section>

        <section className={`filter ${fullScreen ? "hide" : ""}`}>
            <Tabs
                activeKey={activeKey}
                onChange={key => {
                    const top = document.getElementById(`anchor-${key}`)?.offsetTop
                    document.querySelector(".page .content")?.scrollTo({
                        top: top || 0,
                        behavior: "smooth"
                    })
                }}
            >
                {data.map((item: any) => (
                    <Tabs.Tab title={item.alias} key={item.alias} />
                ))}
            </Tabs>
        </section>
        <section className={`content ${fullScreen ? "hide" : ""}`}>
            <section className="tag-list">
                {data?.map(viewItem)}
            </section>
        </section>

        <ToTop />

        <OpenAppBanner hide={fullScreen} app="zj" title="中济经络穴位" summary="中医经络穴位针灸的3D模型活地图">
            <OpenAppButton url="https://www.lighttcm.com/invite" appid="wx96950494c868cde4" iOSMarketUrl="itms-apps://itunes.apple.com/app/id1554030035" androidMarketUrl="market://details?id=com.mc.acupoint_3d" />
        </OpenAppBanner>
    </section>
}

