import {configureStore} from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import globalReducer from './global';

//store，用于管理全局状态
export const store = configureStore({
    reducer: {
        global: globalReducer,
    }, middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});


export function subscribeTrackCodeChange() {
    return new Promise((resolve, reject) => {
        const oldNextTrackCode = store.getState().global.nextTrackCode

        const unsubscribe = store.subscribe(() => {
            const {nextTrackCode} = store.getState().global
            if (nextTrackCode !== oldNextTrackCode) {
                console.log('nextTrackCode changed successfully.')
                resolve(nextTrackCode)
                unsubscribe() // 取消订阅
            }
        })
        setTimeout(() => {
            unsubscribe() // 取消订阅
            reject() // 超时
        }, 10000)
    })
}