import {useEffect, useRef, useState} from "react";
import {Tabs} from "antd-mobile";
import {useThrottleFn} from 'ahooks'
import "./Meridian.css"
import MeridianFilter from "./components/Filter";
import {CloseOutline, DownFill, RightOutline} from "antd-mobile-icons";
import {useDispatch, useSelector} from "react-redux";
import {selectCacheData, selectJsonRequest, selectUser, setCacheData} from "../state/global";
import {Link, useParams} from "react-router-dom";
import ToTop from "../components/ToTop";
import SearchBySystem from "../module.search/SearchBySystem";
import OpenAppBanner from "../components/OpenAppBanner";
import OpenAppButton from "../components/OpenAppButton";
import PageEnter from "../functions/umeng/pageEnter";
import funcFilterSelectedRemove from "../functions/funcFilterSelectedRemove";

const tabHeight = 152

export function Meridian(params: any) {
    let {meridianType}: any = useParams();

    // 正经数据缓存key
    const dataCacheKey = `/meridian/${meridianType}`
    // 筛选项数据缓存key
    const filterCacheKey = "/meridian/filter"

    // 缓存数据
    const cacheData = useSelector(selectCacheData)
    const dispatch = useDispatch()

    const [fullScreen, setFullScreen] = useState(false)

    const [activeKey, setActiveKey] = useState()

    // 是否打开筛选
    const [filterActive, setFilterActive] = useState(false)
    // 选择的筛选组
    const [filterCurrentGroup, setFilterCurrentGroup]: [string | null, Function] = useState(null)
    // 选择的筛选项目
    const [filterSeleted, setFilterSeleted] = useState([])
    // 当前登录的用户
    const user = useSelector(selectUser)

    // json请求客户端
    const JsonRequest = useSelector(selectJsonRequest)

    // 分页数据
    const [data, setData]: any = useState(cacheData[dataCacheKey] || [])
    // 分页数据
    const [filterData, setFilterData]: any = useState(cacheData[filterCacheKey] || [])

    const filterRef: any = useRef(null)

    useEffect(() => {
        // 记录数据
        PageEnter("PageModuleListEnter", "十二正经", {path: window.location.pathname, userId: user?.id})
    }, [])

    useEffect(() => {
        const content = document.querySelector(".page .content")
        content?.addEventListener('scroll', handleScroll)

        reloadFilter().then((initData: any) => dispatch(setCacheData({key: filterCacheKey, value: initData})))
        reloadData(filterSeleted).then((initData: any) => dispatch(setCacheData({key: dataCacheKey, value: initData})))

        return () => {
            content?.removeEventListener('scroll', handleScroll)
        }
    }, [filterSeleted])

    // 监听滑动
    const {run: handleScroll} = useThrottleFn(
        () => {
            let currentKey = data[0].alias
            for (const item of data) {
                const element = document.getElementById(`anchor-${item.alias}`)
                if (!element) continue
                const rect = element.getBoundingClientRect()
                if (rect.top <= tabHeight) {
                    currentKey = item.alias
                } else {
                    break
                }
            }
            setActiveKey(currentKey)
        },
        {
            leading: true,
            trailing: true,
            wait: 100,
        }
    )

    // 加载数据
    const reloadData = async (filterSeleted: Array<string>) => {
        const {data} = await JsonRequest.get("/v1/contents/meridian-points", {
            type: meridianType,
            cateIds: filterSeleted.map((({id}: any) => id))
        });
        setData(data);
        setActiveKey(data[0]?.alias);
        return data;
    }

    // 加载筛选项
    const reloadFilter = async () => {
        if (filterData.length > 0) return filterData
        const {data} = await JsonRequest.get("/v1/content/cates/group-trees", {systemCode: "acupoint_ancestor"})
        setFilterData(data)
        return data
        //     setFilterCurrentGroup(data)
    }

    const handleFilterClick = (group: any) => {
        setFilterCurrentGroup(group)
        setFilterActive(true)
    }

    useEffect(() => {
        const content = document.querySelector(".page .content")

        content?.addEventListener('scroll', handleScroll)
        return () => {
            content?.removeEventListener('scroll', handleScroll)
        }
    }, [])

    // 筛选项选择
    const handleFilterRemove = (item: any) => {
        funcFilterSelectedRemove(item, filterSeleted, setFilterSeleted)
        filterRef.current?.handleSelectFilter(item.parentId, item)
    }
    // 筛选过的筛选项视图
    const viewFilterSeleted = () => {
        return filterSeleted?.map?.((item: any) => <div className="item" onClick={() => handleFilterRemove(item)}
                                                        key={item.id}>{item.cateName} <CloseOutline/></div>)
    }

    return <section className="page meridian-index">
        <section className="search">
            <SearchBySystem placeholder="搜索经络穴位" systemCode='acupoint_ancestor'
                            onFullScreen={() => setFullScreen(true)} onBack={() => setFullScreen(false)}/>
        </section>

        <section className={`filter ${fullScreen ? "hide" : ""}`}>
            <div className="adm-dropdown">
                <div className="adm-dropdown-nav">
                    {
                        filterData?.map((item: any) =>
                            <div className="adm-dropdown-item" key={item.id} onClick={() => handleFilterClick(item.id)}>
                                <div className="adm-dropdown-item-title">
                                    <span className="adm-dropdown-item-title-text">{item.groupName}</span>
                                    <span className="adm-dropdown-item-title-arrow"><DownFill/></span>
                                </div>
                            </div>)
                    }
                </div>
            </div>

            <Tabs
                activeKey={activeKey}
                onChange={key => {
                    const top = document.getElementById(`anchor-${key}`)?.offsetTop
                    document.querySelector(".page .content")?.scrollTo({
                        top: top || 0,
                        behavior: "smooth"
                    })
                }}>
                {data.map((item: any) => (
                    <Tabs.Tab title={item.alias} key={item.alias}/>
                ))}
            </Tabs>
            <div className="selected-filter">
                {viewFilterSeleted()}
            </div>
        </section>
        <section className={`content ${fullScreen ? "hide" : ""}`}>
            {data.filter((meridian: any) => meridian.acupoints?.length > 0).map((meridian: any) => (
                <div className="tag-list" key={meridian.id}>
                    <section className="block cate">
                        <Link to={`/meridian/detail/${meridian.id}`}
                              state={{title: meridian.meridianName, back: window.location.pathname}}
                              className="title no-sticky" key={meridian.id} id={`anchor-${meridian.alias}`}>
                            <span>{meridian.meridianName}<span className="sub">{meridian.season}</span></span>
                            <span className="forward"><RightOutline/></span>
                        </Link>
                        <section className="tags">
                            {
                                meridian.acupoints?.map((acupoint: any) => <Link id={acupoint.id}
                                                                                 state={{
                                                                                     title: acupoint.acupointName,
                                                                                     back: window.location.pathname
                                                                                 }}
                                                                                 to={`/meridian/detail/${acupoint.id}`}
                                                                                 className="tag">{acupoint.acupointName}</Link>)
                            }
                        </section>
                    </section>
                </div>
            ))}
        </section>
        <ToTop/>
        <MeridianFilter
            ref={filterRef}
            active={filterActive}
            filterCurrentGroup={filterCurrentGroup}
            filterData={filterData}
            currentSelected={filterSeleted}
            meridianType={meridianType}
            onSubmit={(filterItems: any) => setFilterSeleted(filterItems)}
            onClose={() => setFilterActive(false)}/>
        <OpenAppBanner hide={fullScreen} app="zj" title="中济经络穴位" summary="中医经络穴位针灸的3D模型活地图">
            <OpenAppButton url="https://www.lighttcm.com/invite" appid="wx96950494c868cde4"
                           iOSMarketUrl="itms-apps://itunes.apple.com/app/id1554030035"
                           androidMarketUrl="market://details?id=com.mc.acupoint_3d"/>
        </OpenAppBanner>
    </section>
}

