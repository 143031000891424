import { PlayOutline, SoundOutline } from "antd-mobile-icons";
import { useRef, useState } from "react";

export default function IconAudio({ src ,info}: any) {
    const audioRef: any = useRef()

    const [playing, setPlaying] = useState(false)
    const [canplay, setCanplay] = useState(true)

    const handleClickPlay = () => {
        if (playing) {
            audioRef?.current?.pause()
            setPlaying(false)
        }
        else {
            // document.querySelectorAll("audio").forEach((ele: any) => ele.pause())
            audioRef?.current?.play()
            window.aplus_queue.push({
                action: 'aplus.record',
                arguments: ["AudioClickPlay", "CLK", {type: info.type, id: info.id, userId: info.userId}]
            });
        }
    }

    return <a onClick={handleClickPlay}>
        {canplay && (playing ? <PlayOutline /> : <SoundOutline />)}
        <audio ref={audioRef} src={src} onPlay={() => setPlaying(true)} onCanPlay={() => setCanplay(true)} onEnded={() => setPlaying(false)}></audio>
    </a>
}