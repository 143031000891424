import './Oppose.css'
import {Divider, Space} from "antd-mobile";
import DetailBox from "../components/detail-box/DetailBox";

export default function Oppose() {

    return <DetailBox title='十八反十九畏' type='receipe'>
        <section className='oppose-content'>
            <div className='oppose-content-item'>
                <Space direction='vertical' style={{'--gap-vertical': '20px'}}>
                    <div className='oppose-title'>一、十八反歌诀：</div>
                    <div className='oppose-primary-text oppose-poesy'>
                        <span>本草明言十八反，半蒌贝蔹及攻乌，</span>
                        <span>藻戟遂芫俱战草，诸参辛芍叛藜芦。</span>
                    </div>
                    <div className='oppose-desc-text'>
                        十八反包括32种中药的相反。如果相反药物合用，会增强或产生毒副作用，原则上应禁用。即：半夏、瓜蒌（包括瓜蒌皮、蒌仁、天花粉）、贝母（包括浙贝母、川贝母）、白蔹、白及反乌头（包括川乌、草乌、附子、天雄、侧子）；海藻、大戟、甘遂、芫花反甘草；人参、党参、太子参、丹参、玄参、沙参、苦参、细辛、白芍、赤芍反藜芦。
                    </div>
                </Space>
            </div>
            <Divider/>
            <div className='oppose-content-item'>
                <Space direction='vertical' style={{'--gap-vertical': '20px'}}>
                    <div className='oppose-title'>二、十九畏歌诀：</div>
                    <div className='oppose-primary-text oppose-poesy'>
                        <span>硫黄原是火中精，朴硝一见便相争。</span>
                        <span>水银莫与砒霜见，狼毒最怕密陀僧。</span>
                        <span>巴豆性烈最为上，偏与牵牛不顺情。</span>
                        <span>丁香莫与郁金见，牙硝难合京三棱。</span>
                        <span>川乌草乌不顺犀，人参最怕五灵脂。</span>
                        <span>官桂善能调冷气，若逢石脂便相欺。</span>
                        <span>大凡修合看顺逆，炮爁炙煿莫相依。</span>
                    </div>
                    <div className='oppose-desc-text'>
                        畏即相畏，指一种药物的毒性、副作用被另一种药物减少或抑制。即：硫黄畏朴硝、芒硝、玄明粉；水银畏砒霜、信石、红砒、白砒；狼毒畏密陀僧；巴豆、巴豆霜畏牵牛子（黑丑、白丑）；公丁香、母丁香畏郁金（黑郁金、黄郁金）：牙硝、玄明粉畏三棱；川乌、草乌、附子、天雄畏犀牛角、广角；人参畏五灵脂；肉桂、官桂、桂枝畏赤石脂。这些药物在配伍使用时应慎重。
                        中医药物配伍分为：相须、相使、相畏、相惡、相杀、相反。所以说十八反是指两种药物相反。
                    </div>
                </Space>
            </div>
            <Divider/>
            <div className='oppose-content-item'>
                <Space direction='vertical' style={{'--gap-vertical': '20px'}}>
                    <div className='oppose-title'>三、用药禁忌：</div>
                    <div className="oppose-primary-text">1、配伍禁忌</div>
                    <div className="oppose-desc-text">
                        《神农本草经•序例》指出“勿用相恶、相反者”，“若有毒宜制，可用相畏、相杀者尔，勿合用也”。自宋代以后将“相畏”关系也列为配伍禁忌，与“相恶”混淆不清。
                        因此“十九畏”的概念，与“配伍”一节中所谈的七情中的“相畏”，涵义并不相同。“十九畏”和“十八反”诸药，有一部分同实际应用有些出历代医家也有所论及，引古方为据，证明某些药物仍然可以合用。如感应丸中的巴豆与牵牛同用；甘遂半夏汤以甘草同甘遂并列；散肿溃坚汤、海藻玉壶汤等均合用甘草草和海藻；十香返魂丹是将丁香、郁金同用；大活络丹乌头与犀角同用等等。现代这方面的研究工作做得不多，有些实验研究初步表明，如甘草、甘遂两种药合用时，毒性的大小主要取决于甘草的用量比例，甘草的剂量若相等或大于甘遂，毒性较大；又如贝母和半夏分别与乌头配伍，未见明显的增强毒性。而细辛配伍藜芦，则可导致实验动物中毒死亡。由于对“十九畏”和“十八反”的研究，还有待进一步作较深入的实验和观察，并研究其机理，因此，目前应采取慎重态度。一般说来，对于其中一些药物，若无充分根据和应用经验，仍须避免盲目配合应用。
                    </div>
                    <div className="oppose-primary-text">2、孕妇服药禁忌</div>
                    <div className="oppose-desc-text">
                        某些药物具有损害胎元以致堕胎的副作用，所以应该作为妊娠禁忌的药物。根据药物对于胎元损害程度的不同，一般可分为禁用与慎用二类。禁用的大多是毒性较强，或药性猛烈的药物，如巴豆、牵牛、大戟、斑蝥、商陆、麝香、三棱、莪术、水蛭、虻虫等；慎用的包括通经去瘀行气破滞，以及辛热等药物，如桃仁、红花、大黄、枳实、附子、干姜、肉桂等。凡禁用的药物，绝对不能使用；慎用的药物，则可根据孕妇患病的情况，斟情使用。但没有特殊必要时，应尽量避免，以防发生事故。
                    </div>
                    <div className="oppose-primary-text">3、饮食禁忌</div>
                    <div className="oppose-desc-text">
                        饮食禁忌简称食忌，也就是通常所说的忌口。在古代文献上有常山忌葱；地黄、何首乌忌葱、蒜、萝卜，薄荷忌鳖肉；茯苓忌醋；鳖甲忌苋菜：以及蜜忌葱等记载。这说明服用某些药时不可同吃某些食物。另外，由于疾病的关系，在服药期间，凡属生冷、粘腻、腥臭等不易消化及有特殊刺激性的食物，都应根据需要予以避免。高烧患者还应忌油。
                    </div>
                </Space>
            </div>
        </section>
    </DetailBox>
}