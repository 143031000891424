import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Tools from "../functions/tools";
import { selectInWeChat } from "../state/global";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'wx-open-launch-app': any;
        }
    }
}

export default function OpenAppButton({ iOSMarketUrl, androidMarketUrl, appName, appid, url }: any) {
    const inWeChat = useSelector(selectInWeChat)
    const openBtn: any = useRef(null);

    useEffect(() => {
        const launchFunc = function () {
            Tools.copy(window.location.href)
            // 点击按钮记录
            window.aplus_queue.push({
                action: 'aplus.record',
                arguments: ["OpenAPPButtonClick", "CLK", {}]
            });
        }

        const errFunc = function () {
            if (Tools.isIOS()) {
                window.location.href = iOSMarketUrl
                alert(`您还没有安装${appName}，或者版本需要升级`)
            } else if (Tools.isAndroid()) {
                window.location.href = androidMarketUrl
                alert(`您还没有安装${appName}，或者版本需要升级`)
            }
            Tools.copy(window.location.href)
        }

        const openTagsErr = (e: any) => {
            alert(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
        }

        document.addEventListener('WeixinOpenTagsError', openTagsErr);

        if (inWeChat)
            window.wx?.ready(() => {
                openBtn.current?.addEventListener('launch', launchFunc);
                openBtn.current?.addEventListener('error', errFunc);

            })

        return () => {
            if (inWeChat) {
                openBtn.current?.removeEventListener('launch', launchFunc);
                openBtn.current?.removeEventListener('error', errFunc);
                document.removeEventListener("WeixinOpenTagsError", openTagsErr);
            }
        };
    }, [])

    const styleStr = `
    .button.open-app {
        right: 5px;
        height: 23px;
        margin: 0 10px;
        border-radius: 35px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue',
        helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
        'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;

        background-color: #C1A16B;
        -webkit-tap-highlight-color: transparent !important;
        outline: none !important;
    }`

    return inWeChat ? <wx-open-launch-app id="wechat-launch-btn" ref={openBtn} appid={appid}>
        <script type="text/wxtag-template">
            <style>
                {styleStr}
            </style>
            <a className="button open-app">打开APP</a>
        </script>
    </wx-open-launch-app> :
        <a href={url} className="button">打开APP</a>
}
