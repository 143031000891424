import { useEffect, useRef, useState } from "react";
import { Button, Dropdown } from "antd-mobile";
import "./Receipe.css"
import { useDispatch, useSelector } from "react-redux";
import { selectCacheData, selectJsonRequest, selectUser, setCacheData } from "../state/global";
import { CloseOutline } from "antd-mobile-icons";
import filtedTotalNumber from "../functions/filtedTotalNumber";
import funcFilterSelected from "../functions/funcFilterSelected";
import funcFilterSelectedRemove from "../functions/funcFilterSelectedRemove";
import { Link } from "react-router-dom";
import ToTop from "../components/ToTop";
import SearchBySystem from "../module.search/SearchBySystem";
import OpenAppBanner from "../components/OpenAppBanner";
import PageEnter from "../functions/umeng/pageEnter";

// 筛选项数据缓存key
const filterCacheKey = "/receipe/filter"
// 筛选项数据缓存key
const filterSelectedCacheKey = "/receipe/filter/seleted"
// 筛选项数据缓存key
const filterSelectedGroupIdCacheKey = "/receipe/filter/groupId"
export function Receipe(params: any) {
    const [fullScreen, setFullScreen] = useState(false)
    const dispatch = useDispatch()

    // 列表数据
    const [data, setData]: any = useState([])
    // 缓存数据
    const cacheData = useSelector(selectCacheData)
    // 分页数据
    const [filterData, setFilterData]: any = useState(cacheData[filterCacheKey] || [])
    // 筛选后的总数量
    const [filtedTotalCount, setFiltedTotalCount]: any = useState(0)
    // 选择的筛选组
    const [filterCurrentGroup, setFilterCurrentGroup]: [string | null, Function] = useState(cacheData[filterSelectedGroupIdCacheKey] || null)
    // 选择的筛选项目
    const [filterSelected, setFilterSelected]: any = useState(cacheData[filterSelectedCacheKey] || [])
    // json请求客户端
    const JsonRequest = useSelector(selectJsonRequest)

    // 筛选器的引用
    const filterRef: any = useRef()

    // 当前登录的用户
    const user = useSelector(selectUser)

    useEffect(() => {
        // 记录数据
        PageEnter("PageModuleListEnter", "中药方剂", { path: window.location.pathname, userId: user?.id })
    }, [])

    useEffect(() => {
        reloadFilter()
            .then((groupId) => reloadData(groupId, filterSelected))
    }, [])


    useEffect(() => {
        filterCurrentGroup && reloadData(filterCurrentGroup, filterSelected)
        reloadTotalNum()
    }, [filterCurrentGroup, filterSelected])


    // 加载数据
    const reloadData = (filterCurrentGroup: string | null, filterSeleted: Array<string> | null) => {
        let _filterSeleted: any = filterSeleted || []
        // 如果没有选择筛选项，则删掉
        if (_filterSeleted.length === 0)
            _filterSeleted = null
        JsonRequest.get("/v1/contents/cate-nests", {
            systemCode: "prescription",
            groupId: filterCurrentGroup,
            cateIds: _filterSeleted?.map(({ id }: any) => id)
        }).then(({ data }) => setData(data))
    }


    // 加载筛选项
    const reloadFilter = async () => {
        const { data } = await JsonRequest.get("/v1/content/cates/group-trees", { systemCode: "prescription" })
        setFilterData(data)
        dispatch(setCacheData({ key: filterCacheKey, value: data }))
        let _filterCurrentGroup = filterCurrentGroup
        if (filterSelected.length === 0) {
            // state的set方法又延迟，不能及时生效。这里需要强行设置
            _filterCurrentGroup = data[0]?.id
            setFilterCurrentGroupAndCache(_filterCurrentGroup)
        }
        return _filterCurrentGroup
    }

    // 加载查看的数量
    const reloadTotalNum = async () => {
        setFiltedTotalCount(await filtedTotalNumber(JsonRequest, "prescription", filterSelected))
    }

    // 设置筛选项和缓存
    const setFilterSelectedAndCache = (value: any) => {
        setFilterSelected(value)
        dispatch(setCacheData({ key: filterSelectedCacheKey, value: value }))
    }


    // 设置筛选项和缓存
    const setFilterCurrentGroupAndCache = (value: any) => {
        setFilterCurrentGroup(value)
        dispatch(setCacheData({ key: filterSelectedGroupIdCacheKey, value: value }))
    }

    // 筛选过的筛选项视图
    const viewFilterSeleted = () => {
        return filterSelected?.map((item: any) => <div className="item" onClick={() => handleFilterRemove(item)}
            key={item.id}>{item.cateName} <CloseOutline /></div>)
    }

    // 单个项目的样式
    const viewItem = (item: any) => {
        switch (item.type) {
            case "content_Cate":
                return <section className="block cate" key={item.id}>
                    <span className="title">{item.name}</span>
                    <div className="tags">
                        {item.sons?.map(viewItem)}
                    </div>
                </section>
            case "content":
                return <Link key={item.id} id={item.id} state={{ title: item.name, back: window.location.pathname }} to={`/receipe/detail/${item.id}`}
                    className="tag">{item.name}</Link>

            default:
                break;
        }
    }


    // 筛选项组修改
    const handleFilterGroupChange = (key: any) => {
        if (!!key) {
            setFilterCurrentGroupAndCache(key)
            if (filterCurrentGroup !== key) {
                setFilterSelectedAndCache([])
            }
        }
    }

    // 筛选项选择
    const handleFilterSelect = (item: any) => {
        if (!filterSelected.find(({id}: any) => id === item.id)) {
            // 记录点击筛选
            window.aplus_queue.push({
                action: 'aplus.record',
                arguments: ["FilterSelectClick", "CLK", {type: "prescription", value: item.cateName}]
            });
        }
        funcFilterSelected(item, filterSelected, setFilterSelectedAndCache)
    }
    // 筛选项选择
    const handleFilterRemove = (item: any) => {
        funcFilterSelectedRemove(item, filterSelected, setFilterSelectedAndCache)
    }

    // 筛选提交
    const handleFilterSubmit = () => {
        filterRef?.current?.close()
    }

    // 筛选重制
    const handleFilterReset = () => {
        setFilterCurrentGroupAndCache(filterData[0]?.id)
        setFilterSelected([])
    }


    return <section className="page receipe-index">
        <section className="search">
            <SearchBySystem placeholder="搜索方剂" systemCode='prescription'
                onFullScreen={() => setFullScreen(true)} onBack={() => setFullScreen(false)} />
        </section>

        <section className={`filter ${fullScreen ? "hide" : ""}`}>
            <Dropdown ref={filterRef} closeOnClickAway onChange={handleFilterGroupChange}>
                {
                    filterData?.map(({ id, groupName, trees }: any) => {
                        return <Dropdown.Item key={id} title={groupName} highlight={true}
                            className={`${id === filterCurrentGroup && "adm-dropdown-item-active"}`}>
                            <div style={{ padding: 12 }} className="hern tags">
                                {
                                    trees?.map((item: any) => <div onClick={() => handleFilterSelect(item)}
                                        key={item.id}
                                        className={`tag ${filterSelected?.map(({ id }: any) => id).indexOf(item.id) > -1 && "selected"}`}>{item.cateName}</div>)
                                }
                            </div>

                            <div className="receipe buttons">
                                <Button className="reset" onClick={handleFilterReset}>重置</Button>
                                <Button className="submit"
                                    onClick={handleFilterSubmit}>查看{filtedTotalCount}种方剂</Button>
                            </div>
                        </Dropdown.Item>
                    })
                }
            </Dropdown>

            <div className="selected-filter">
                {viewFilterSeleted()}
            </div>
        </section>
        <section className={`content ${fullScreen ? "hide" : ""}`}>
            <section className="tag-list">
                {data?.map(viewItem)}
            </section>
        </section>

        <ToTop />
        <OpenAppBanner hide={fullScreen} app="zy" title="知源中医" summary="古籍·中医·方剂大全" >
            <a href="https://new.mobile.zhiyuanzhongyi.com/#/zyIntro" className="button">打开APP</a>
        </OpenAppBanner>

    </section >
}