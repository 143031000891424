import {FloatingBubble, Image} from "antd-mobile";
import toTopImage from "../images/to-top.png"
import {useState} from "react";

export default function ToTop() {
    const [offset, setOffset] = useState({x: -24, y: -24})
    return <FloatingBubble
        onClick={() => document.querySelector(".page .content")?.scrollTo({top: 0, behavior: "smooth"})}
        axis='xy'
        magnetic='x'
        style={{
            '--initial-position-bottom': '100px',
            '--initial-position-right': '24px',
            '--edge-distance': '24px',
            zIndex: 9
        }}
        onOffsetChange={offset => {
            if (offset.y < 0) {
                setOffset(offset)
            }
        }}
        offset={offset}
    >
        <Image src={toTopImage}/>
    </FloatingBubble>
}