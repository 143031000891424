import Search from "./module.search/Search";

import imgMeridianClassic from "./images/home/meridian_classic.webp"
import imgAcupointDongs from "./images/home/acupoint_dongs.webp"
import imgFood from "./images/home/food.webp"
import imgHerb from "./images/home/herb.webp"
import imgIllness from "./images/home/illness.webp"
import imgMedicine from "./images/home/medicine.webp"
import imgMeridianOutter from "./images/home/meridian_outter.webp"
import imgMerdianQi from "./images/home/meridian_qi.webp"
import imgRecipe from "./images/home/recipe.webp"

import toolBG from "./images/home/tool-bg.webp"
import iconEighteenReflexes from "./images/home/icon-eighteen-reflexes.webp"
import iconConversion from "./images/home/icon-conversion.webp"

import "./Home.css"
import { useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import OpenAppButton from "./components/OpenAppButton";
import {setCacheData} from "./state/global";
import {useDispatch} from "react-redux";
import NeedSubscribe from "./components/NeedSubscribe";

export default function Home() {
    const [fullScreen, setFullScreen] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    return <section className="page home">
        <section className="search">
            <Search onFullScreen={() => setFullScreen(true)} onBack={() => setFullScreen(false)} />
        </section>
        <section className={`content ${fullScreen ? "hide" : ""}`}>
            <section className="grid">
                <div className="row">
                    <Link to="/meridian/1" className="item">
                        <img src={imgMeridianClassic}></img>
                        <span className="title">十二正经</span>
                    </Link>
                    <Link to="/meridian-dongs" className="item">
                        <img src={imgAcupointDongs}></img>
                        <span className="title">董氏奇穴</span>
                    </Link>
                    <div className="item" onClick={() => {
                        dispatch(setCacheData(null)) // 清空筛选缓存
                        navigate('/herb')
                    }}>
                        <img src={imgHerb}></img>
                        <span className="title">中草药</span>
                    </div>
                </div>
                <div className="row">
                    <Link to="/meridian/2" className="item">
                        <img src={imgMerdianQi}></img>
                        <span className="title">奇经八脉</span>
                    </Link>
                    <Link to="/receipe" className="item">
                        <img src={imgRecipe}></img>
                        <span className="title">方剂</span>
                    </Link>
                    <Link to="/cuisine" className="item">
                        <img src={imgMedicine}></img>
                        <span className="title">药膳</span>
                    </Link>
                </div>
                <div className="row">
                    <Link to="/meridian/3" className="item">
                        <img src={imgMeridianOutter}></img>
                        <span className="title">经外奇穴</span>
                    </Link>
                    <Link to="/illness" className="item">
                        <img src={imgIllness}></img>
                        <span className="title">疾病百科</span>
                    </Link>
                    <Link to="/food" className="item">
                        <img src={imgFood}></img>
                        <span className="title">食材</span>
                    </Link>
                </div>
            </section>
            <section className="ad">
                <div className="item zyzy" >
                    <a href="https://new.mobile.zhiyuanzhongyi.com/#/zyIntro" className="button">打开APP</a>
                </div>
                <div className="item acupoint">
                    <OpenAppButton url="https://www.lighttcm.com/invite" appid="wx96950494c868cde4" iOSMarketUrl="itms-apps://itunes.apple.com/app/id1554030035" androidMarketUrl="market://details?id=com.mc.acupoint_3d" />
                </div>
            </section>
            <section className="tools">
                <div className="title">常用工具</div>

                <div className="row">
                    <Link to="/measure" className="item">
                        <div className="title">计量转换</div>
                        <img className="bg" src={toolBG}></img>
                        <img className="icon" src={iconConversion}></img>
                    </Link>
                    <Link to="/oppose" className="item">
                        <div className="title">十八反</div>
                        <img className="bg" src={toolBG}></img>
                        <img className="icon" src={iconEighteenReflexes}></img>
                    </Link>
                </div>
            </section>
        </section>
        <NeedSubscribe/>
    </section>
}