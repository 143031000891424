
import { useSelector } from "react-redux";
import subscribeQrCode from "../images/wechat_qrcode.jpg";
import { Button, Image } from "antd-mobile"
import { selectInWeChat, selectJsonRequest, selectUser } from "../state/global";
import "./NeedSubscribe.css"
import { useEffect, useState } from "react";

export default function NeedSubscribe({ }) {
    const user = useSelector(selectUser)

    const JsonRequest = useSelector(selectJsonRequest)
    const inWeChat = useSelector(selectInWeChat)
    // 获取是否关注了公众号
    const [subscribed, setSubscribed]: any = useState(true)
    // 用户点刷新，但实际没有关注时
    const [textBlink, setTextBlink]: any = useState(false)

    useEffect(() => {
        if (inWeChat && !!user) {
            setSubscribed(user?.subscribe === 1 ? true : false)
        }

        return () => {
        }
    }, [user])


    const reloadSubscribe = async () => {
        const { data } = await JsonRequest.get("/v1/wechat/subscribe", { openId: user?.openId })
        const subscribed = data === 1 ? true : false
        setSubscribed(subscribed)
        return subscribed
    }

    // 用户点击刷新
    const handleClickRefresh = async () => {
        const subscribed = await reloadSubscribe()
        if (!subscribed) {
            setTextBlink(true)
            setTimeout(() => {
                setTextBlink(false)
            }, 1000);
        }
    }

    return subscribed ? <></> : <div id="need-subscribe">
        <Image src={subscribeQrCode} />
        <p className={`click-view ${textBlink && "animation"}`}>
            长按二维码关注“知源百科”公众号即可使用。
        </p>
        <p>
            <Button onClick={() => { handleClickRefresh() }} size="small" color="primary">关注后点我</Button>
        </p>
    </div>
}