import {useSelector} from "react-redux";
import {selectJsonRequest} from "../state/global";
import {useEffect, useState} from "react";
import {CapsuleTabs, Divider} from "antd-mobile";
import './Measure.css'
import MeasureInput from "./MeasureInput";
import imgDivider from '../images/home/divider.webp';
import DetailBox from "../components/detail-box/DetailBox";

export default function Measure() {
    const JsonRequest = useSelector(selectJsonRequest)
    const [data, setData]: any = useState([])
    const [dynastyKey, setDynastyKey] = useState("")

    const [leftUnits, setLeftUnits]: any = useState([])
    const [leftMeasure, setLeftMeasure]: any = useState({});
    const [leftValue, setLeftValue]: any = useState(0);

    const [rightUnits, setRightUnits]: any = useState([])
    const [rightMeasure, setRightMeasure]: any = useState({});
    const [rightValue, setRightValue]: any = useState(0);

    useEffect(() => {
        JsonRequest.get("/v1/unit-convert", {})
            .then(({data}: any) => {
                setData(data)
                setDynastyKey(data[0].dynasty)
                const defaultUnit = data[0] || {};
                setDynasty(defaultUnit);
            })
    }, []);

    const setDynasty = (dynasty: any) => {
        setLeftUnits(dynasty.units)
        setLeftMeasureValue(dynasty.units[0])
    }

    const setLeftMeasureValue = (measure: any) => {
        setLeftMeasure(measure)
        setRightUnits(measure.sons)
        setRightMeasure(measure.sons[0])
        setLeftValue(1)
        setRightValue(1 * measure.sons[0].measure)
    }

    const onLeftValueChange = (v: number) => {
        setLeftValue(v)
        setRightValue((v * rightMeasure.measure).toFixed(2))
    }

    const onRightValueChange = (v: number) => {
        setRightValue(v)
        setLeftValue((v / rightMeasure.measure).toFixed(2))
    }

    return <DetailBox title='古今计量换算' type='receipe'>
        <section className='measure-content'>
            <section className='dynasty-list'>
                <CapsuleTabs activeKey={dynastyKey} onChange={(key) => {
                    setDynasty(data.find((e: any) => e.dynasty === key));
                    setDynastyKey(key)
                }}>
                    {data?.map(({dynastyName, dynasty}: any) => (
                        <CapsuleTabs.Tab title={dynastyName} key={dynasty}/>
                    ))}
                </CapsuleTabs>
            </section>
            <section className='measure-transform'>
                <MeasureInput options={leftUnits} unit={leftMeasure} value={leftValue} onChange={onLeftValueChange}
                              onMeasureChange={(v: any) => setLeftMeasureValue(leftUnits.find((e: any) => e.id === v[0]))}/>
                <span className='measure-equal'>=</span>
                <MeasureInput options={rightUnits} unit={rightMeasure} value={rightValue} onChange={onRightValueChange}
                              onMeasureChange={(v: any) => setRightMeasure(rightUnits.find((e: any) => e.id === v[0]))}/>
            </section>
            <Divider>此结果仅作参考</Divider>
            <img src={imgDivider} className='measure-img-divider'/>
            <section className='sub-measure'>
                <span>梧桐子大=黄豆大</span>
                <span>1方寸匙=金石类2.74克=药末约2克=草木类药末约1克</span>
                <span>一毫升=1克</span>
                <span>蜀椒一升=50克</span>
                <span>半方寸匙=1.5克</span>
                <span>葶苈子一升=60克</span>
                <span>一钱匙=1.5-1.8克</span>
                <span>吴茱萸一升=50克</span>
                <span>一铢=100个泰米的重量</span>
                <span>半夏一升=130克</span>
                <span>虻虫一升 = 16克</span>
                <span>附子大者一枚=20-30克</span>
                <span>附子中者一枚=15克</span>
                <span>强乌头一枚小者=3克</span>
                <span>强乌头一枚大者=5-6克</span>
                <span>杏仁大者10枚=4克</span>
                <span>栀子10枚平均15克</span>
                <span>瓜蒌大小平均一枚=46克</span>
                <span>枳实一枚≈14.4克</span>
                <span>石膏鸡蛋大一枚≈40克</span>
                <span>‘石’：读shí，后来读dàn</span>
                <span>厚朴一尺30克</span>
                <span>‘龠'读yuè</span>
                <span>竹叶一握≈12克</span>
            </section>
        </section>
    </DetailBox>
}