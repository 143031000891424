import {useEffect, useRef, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectJsonRequest, selectUser} from "../../state/global";
import {Swiper, SwiperRef, Tabs} from "antd-mobile";
import SearchResultList from "./SearchResultList";
import './SearchResult.css'
import SearchBySystem from "../SearchBySystem";
import imgNoSearch from "../../images/home/search.webp";
import CustomLoading from "../../components/CustomLoading";

export default function SearchResult() {
    const JsonRequest = useSelector(selectJsonRequest)
    const user = useSelector(selectUser)
    const location = useLocation();
    const {systemCode} = location.state || {};
    const [fullScreen, setFullScreen]: any = useState(false)
    let {keyword}: any = useParams();
    const swiperRef = useRef<SwiperRef>(null)
    const [activeIndex, setActiveIndex]: any = useState(0)
    const [allResult, setAllResult]: any = useState([])
    const [tabs, setTabs]: any = useState([])
    const [loading, setLoading]: any = useState(false);

    useEffect(() => {
        if (systemCode) return;
        getResult()
        setActiveIndex(0)
        swiperRef.current?.swipeTo(0)

        // 点击搜索栏
        window.aplus_queue.push({
            action: 'aplus.record',
            arguments: ["SearchResult", "CLK", {keyword}]
        });
    }, [keyword]);

    const getResult = async () => {
        setLoading(true)
        const {data} = await JsonRequest.get('/v1/elasticsearch',
            {keyword, systemCode, userid: user?.id, pageNumber: 1, pageSize: 5});
        setLoading(false)

        setAllResult(data)
        if (data.length === 0) {
            setTabs([])
            return;
        }
        setTabs([{title: '全部', key: 'all'},
            ...data?.map?.((item: any) => ({title: item.systemName, key: item.systemCode})) ?? []])
    }

    const Empty = () => <div className='no-search'>
        <img src={imgNoSearch} width={128}/>
        <span>没有找到相关内容，试试其他关键字？</span>
    </div>

    const SystemResult = () => <SearchResultList Empty={Empty} systemCode={systemCode} keyword={keyword} show/>

    const NoSystemResult = () => {
        if (loading)
            return <CustomLoading width={80}/>
        if (tabs.length === 0)
            return <Empty/>

        return <>
            <Tabs className="search-result-tabs" activeKey={tabs[activeIndex].key}
                  onChange={key => {
                      const index = tabs.findIndex((item: any) => item.key === key)
                      setActiveIndex(index)
                      swiperRef.current?.swipeTo(index)
                  }}>
                {tabs.map((item: any) => <Tabs.Tab title={item.title} key={item.key}/>)}
            </Tabs>
            <Swiper direction='horizontal' indicator={() => null} className="search-contents"
                    ref={swiperRef} defaultIndex={activeIndex}
                    onIndexChange={index => setActiveIndex(index)}>
                <Swiper.Item>
                    <SearchResultList defaultData={allResult} show={0 === activeIndex}
                                      keyword={keyword} Empty={Empty}
                                      toList={(index: number) => {
                                          setActiveIndex(index)
                                          swiperRef.current?.swipeTo(index)
                                      }}/>
                </Swiper.Item>
                {allResult?.map?.((item: any, index: number) =>
                    <Swiper.Item key={item.systemCode}>
                        <SearchResultList Empty={Empty} systemCode={item.systemCode}
                                          show={index + 1 === activeIndex}
                                          keyword={keyword}/>
                    </Swiper.Item>)}
            </Swiper>
        </>
    }

    return <section className="page">
        <section className="search">
            <SearchBySystem onFullScreen={() => setFullScreen(true)} onBack={() => setFullScreen(false)}
                            defaultSearch={keyword} systemCode={systemCode} replace/>
        </section>
        <section className={`content ${fullScreen ? "hide" : ""} search-result-page`}>
            {systemCode ? <SystemResult/> : <NoSystemResult/>}
        </section>
    </section>
}