import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectJsonRequest, selectUser } from "../../state/global";
import './SearchResultList.css';
import { Link } from "react-router-dom";
import imgMore from "../../images/home/more.webp"
import imgBestMatch from "../../images/home/best-match.webp"
import { InfiniteScroll } from "antd-mobile";
import imgDongsIcon from "../../images/home/dongs-icon.webp";
import CustomImage from "../../components/CustomImage";
import CustomLoading from "../../components/CustomLoading";

export default function SearchResultList({ defaultData = [], systemCode, keyword, show, toList, Empty }: any) {
    const JsonRequest = useSelector(selectJsonRequest)
    const user = useSelector(selectUser)
    const [data, setData]: any = useState([]);
    const [hasMore, setHasMore]: any = useState(!!systemCode)
    const [page, setPage]: any = useState(0);
    const [loading, setLoading]: any = useState(systemCode);
    const pageSize = 20;

    useEffect(() => {
        scrollToTop();
        if (show && defaultData?.length == 0)
            initData();
    }, [keyword]);

    useEffect(() => {
        show && data.length === 0 && initData();
    }, [show]);

    const initData = async () => {
        setLoading(true)
        const res = await JsonRequest.get('/v1/elasticsearch', {
            keyword, systemCode, userid: user?.id,
            pageNumber: 1, pageSize,
        })
        setLoading(false)
        setPage(1)
        setData(res.data)
        setHasMore(res.data[0]?.contents.length == pageSize)
    }

    const scrollToTop = () => {
        document.querySelector(`#search-result-list-${systemCode}`)?.scrollTo({ top: 0 })
    }

    const getData = async () => {
        const res = await JsonRequest.get('/v1/elasticsearch', {
            keyword, systemCode, userid: user?.id,
            pageNumber: page + 1, pageSize,
        })
        const resData = res.data;
        setPage(page + 1)
        if (data.length !== 0 && resData.length > 0) {
            const newList = [...data[0].contents, ...resData[0]?.contents]
            const { contents, ...others } = resData[0]
            setData([{ ...others, contents: newList }]);
        } else if (data.length === 0) {
            setData(resData)
        }
        setHasMore(resData[0]?.contents.length == pageSize)
    }

    const getSubTitle = (content: any) => {
        if (!content.aliasName && !content.provenance)
            return '';
        return `（${content.aliasName || content.provenance || ''}）`;
    }

    const ResultList = ({ data }: any) => data?.contents.map((content: any, index: number) => (
        <Link to={`/detail/${content.id}`} className='search-result-item' key={content.id} state={{ back: window.location.pathname }}
            style={index ? {} : { border: 'none' }}>
            {content.icon && <CustomImage lazy className='search-result-item-icon' src={content.icon} />}
            <div className='search-result-item-body'>
                <div className="search-result-item-title">
                    <div dangerouslySetInnerHTML={{ __html: `${content.contentName} ${getSubTitle(content)}` }} />
                    {content.systemCode === 'acupoint_dongs' && <img src={imgDongsIcon} width={20} />}
                </div>
                <div className='search-result-item-content'
                    dangerouslySetInnerHTML={{ __html: `${content.medias?.map((item: any) => item.description).join('\n')}` }} />
            </div>
            {content.isBestMatch && <img src={imgBestMatch} alt="最佳匹配" className='search-result-best-match' />}
        </Link>
    ))

    const SystemDataList = () => {
        if (loading)
            return <CustomLoading width={80} />
        if (data.length === 0)
            return <Empty />
        return <>
            <ResultList data={data[0]} />
            <InfiniteScroll loadMore={() => getData()} hasMore={hasMore} />
        </>
    }

    const DefaultDataList = () => {
        if (defaultData.length === 0)
            return <Empty />
        return defaultData?.map?.((item: any, index: number) => (
            <div className={`system-item ${item.systemCode}`} key={item.systemCode}>
                <div className='system-name-bar' onClick={() => toList && toList(index + 1)}>
                    <span className='system-name-bar-name'>{item.systemName}</span>
                    <span className='system-name-bar-more'>更多 <img src={imgMore} width={16} /></span>
                </div>
                <ResultList data={item} />
            </div>
        ))
    }

    return <div className='search-result-list' id={`search-result-list-${systemCode}`}>
        {!!systemCode ? <SystemDataList /> : <DefaultDataList />}
    </div>
}