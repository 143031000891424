import {useEffect, useRef, useState} from "react";
import {Button, Dropdown} from "antd-mobile";
import {CloseOutline, RightOutline} from "antd-mobile-icons";
import {useDispatch, useSelector} from "react-redux";
import {selectCacheData, selectJsonRequest, selectUser, setCacheData} from "../state/global";
import filtedTotalNumber from "../functions/filtedTotalNumber";
import funcFilterSelected from "../functions/funcFilterSelected";
import {Link} from "react-router-dom";
import ToTop from "../components/ToTop";
import SearchBySystem from "../module.search/SearchBySystem";
import OpenAppBanner from "../components/OpenAppBanner";
import OpenAppButton from "../components/OpenAppButton";
import PageEnter from "../functions/umeng/pageEnter";
import CustomImage from "../components/CustomImage";
import CustomLoading from "../components/CustomLoading";
import './Herb.css'


// 筛选项数据缓存key
const filterCacheKey = "/herb/filter"
// 筛选项数据缓存key
const filterSelectedCacheKey = "/herb/filter/seleted"
// 筛选项数据缓存key
const filterSelectedGroupIdCacheKey = "/herb/filter/groupId"

export function Herb() {
    const [fullScreen, setFullScreen] = useState(false)
    const dispatch = useDispatch()
    // 缓存数据
    const cacheData = useSelector(selectCacheData)
    const [filterData, filterSelected, groupSelected] = [cacheData[filterCacheKey], cacheData[filterSelectedCacheKey], cacheData[filterSelectedGroupIdCacheKey]]

    // 列表数据
    const [list, setList]: any = useState([])

    // 筛选后的总数量
    const [filtedTotalCount, setFiltedTotalCount]: any = useState(0)

    // json请求客户端
    const JsonRequest = useSelector(selectJsonRequest)
    const [loading, setLoading]: any = useState(false);

    // 筛选器的引用
    const filterRef: any = useRef()

    // 当前登录的用户
    const user = useSelector(selectUser)

    useEffect(() => {
        // 记录数据
        PageEnter("PageModuleListEnter", "中草药", {path: window.location.pathname, userId: user?.id})

        // 加载筛选项
        reloadFilter()
            .then((_filterData) => {
                if (groupSelected) {
                    reloadData(groupSelected, filterSelected || [])
                } else {
                    reloadData(_filterData[0].id, filterSelected || [])
                }
            })
    }, [])

    const isUpdate = useRef(false)
    useEffect(() => {
        if (isUpdate.current) {
            reloadData(groupSelected, filterSelected || [])
            isUpdate.current = false
        }
    }, [filterSelected]);

    // 加载数据
    const reloadData = (filterCurrentGroup: string | null, filterSeleted: Array<string>) => {
        let _filterSeleted: any = filterSeleted || []
        // 如果没有选择筛选项，则删掉
        if (_filterSeleted.length === 0)
            _filterSeleted = null
        setLoading(true)
        JsonRequest.get("/v1/contents/cate-nests", {
            systemCode: "herb",
            groupId: filterCurrentGroup,
            cateIds: _filterSeleted?.map?.(({id}: any) => id)
        })
            .then(({data}) => {
                setList(data)
            }).finally(() => {
            setLoading(false)
        })
    }

    // 加载筛选项
    const reloadFilter = async () => {
        const {data} = await JsonRequest.get("/v1/content/cates/group-trees", {systemCode: "herb"})
        // 设置筛选数据
        dispatch(setCacheData({key: filterCacheKey, value: data}))
        return data
    }


    const reloadTotalNum = async (_filterSelected: any) => {
        setFiltedTotalCount(await filtedTotalNumber(JsonRequest, "herb", _filterSelected))
    }

    // 筛选过的筛选项视图
    const viewFilterSeleted = () => {
        return filterSelected?.map((item: any) => <div className="item" onClick={() => handleFilterRemove(item)}
                                                       key={item.id}>{item.cateName} <CloseOutline/></div>)
    }

    // 设置选中的筛选
    const setFilterSelectedAndCache = (value: any) => {
        dispatch(setCacheData({key: filterSelectedCacheKey, value: value}))
    }

    // 设置选中的筛选组
    const setFilterCurrentGroupAndCache = (value: any) => {
        dispatch(setCacheData({key: filterSelectedGroupIdCacheKey, value: value}))
    }

    // 筛选项增加删除
    const handleFilterSelect = (item: any, groupId?: string) => {
        // 设置group
        let _filterSelected = filterSelected ? [...filterSelected] : []
        if (!_filterSelected.find(({id}: any) => id === item.id)) {
            // 记录点击筛选
            window.aplus_queue.push({
                action: 'aplus.record',
                arguments: ["FilterSelectClick", "CLK", {type: "herb", value: item.cateName}]
            });
        }
        if (groupId && groupId !== groupSelected) {
            setFilterCurrentGroupAndCache(groupId)
            _filterSelected = []
        }
        // 设置筛选中
        _filterSelected = funcFilterSelected(item, _filterSelected, setFilterSelectedAndCache)
        reloadTotalNum(_filterSelected) // 重新计算总数
    }

    // 筛选项删除
    const handleFilterRemove = (item: any) => {
        isUpdate.current = true
        handleFilterSelect(item)
    }


    // 筛选提交
    const handleFilterSubmit = () => {
        if (filterData) {
            reloadData(groupSelected || filterData[0]?.id, filterSelected || [])
        }
        filterRef?.current?.close()
    }

    // 筛选重制
    const handleFilterReset = () => {
        setFilterSelectedAndCache([])
    }

    const handleFilterGroupChange = (key: string | null) => {
        if (key === null) {
            handleFilterSubmit()
        }
    }

    return <section className="page herb-index">
        <section className="search">
            <SearchBySystem placeholder="搜索中草药" systemCode='herb' onFullScreen={() => setFullScreen(true)}
                            onBack={() => setFullScreen(false)}/>
        </section>

        <section className={`filter ${fullScreen ? "hide" : ""}`}>
            <Dropdown ref={filterRef} closeOnClickAway onChange={handleFilterGroupChange}>
                {
                    filterData?.map(({id, groupName, trees}: any) => {
                        return <Dropdown.Item key={id} title={groupName} highlight={true}
                                              className={`${id === groupSelected ? "herb-dropdown-item-active" : 'herb-dropdown-item-dark'}`}>
                            <div style={{padding: 12}} className="hern tags">
                                {
                                    trees?.map((item: any) => <div onClick={() => handleFilterSelect(item, id)}
                                                                   key={item.id}
                                                                   className={`tag ${filterSelected?.map(({id}: any) => id).indexOf(item.id) > -1 && "selected"}`}>{item.cateName}</div>)
                                }
                            </div>

                            <div className="receipe buttons">
                                <Button className="reset" onClick={handleFilterReset}>重置</Button>
                                <Button className="submit"
                                        onClick={() => handleFilterSubmit()}>查看{filtedTotalCount}种中草药</Button>
                            </div>
                        </Dropdown.Item>
                    })
                }
            </Dropdown>

            <div className="selected-filter">
                {viewFilterSeleted()}
            </div>
        </section>
        <section className={`content ${fullScreen ? "hide" : ""}`}>
            {loading ? <CustomLoading width={80}/> :
                <section className="list">
                    {list?.map?.(viewItem)}
                </section>}
        </section>
        <OpenAppBanner hide={fullScreen} app="zj" title="中济经络穴位" summary="中医经络穴位针灸的3D模型活地图">
            <OpenAppButton url="https://www.lighttcm.com/invite" appid="wx96950494c868cde4"
                           iOSMarketUrl="itms-apps://itunes.apple.com/app/id1554030035"
                           androidMarketUrl="market://details?id=com.mc.acupoint_3d"/>
        </OpenAppBanner>
        <ToTop/>
    </section>
}

// 单个项目的样式
const viewItem = (item: any) => {
    switch (item.type) {
        case "content_Cate":
            return <section className="block cate" key={item.id}>
                <span className="title">{item.name}</span>
                <div className="items">
                    {item.sons?.map(viewItem)}
                </div>
            </section>
        case "content":
            return <Link to={`/herb/detail/${item.id}`} key={item.id}
                         state={{title: item.name, back: window.location.pathname}} className="item">
                <div className="avatar">
                    <CustomImage src={item.icon} lazy/>
                </div>
                <div className="content">
                    <div className="title">{item.name}</div>
                    <div className="summary">{item.description}</div>
                </div>
                <div className="link-icon">
                    <RightOutline/>
                </div>
            </Link>
        default:
            break;
    }

}