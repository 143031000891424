import {subscribeTrackCodeChange} from "../../state/store";

export function getNextTrackCode(prevTrackCode: string, user: { openid: string, unionId: string }): Promise<string> {
    //  获取 下个分享人code
    return new Promise((resolve, reject) => {
        window.aplus_queue.push({
            action: 'aplus.getNextTrackCode',
            arguments: [{
                appkey: '66826648cac2a664de5a0a0d',
                openid: user.openid,
                unionId: user.unionId,
                trackCode: prevTrackCode, // 上一个分享人的trackCode
                url: window.location.href
            }, (data: { trackCode: string }) => {
                resolve(data.trackCode)
            }]
        })
    })
}

export function updateShareData(shareData: {
    title: string, // 分享标题
    desc: string, // 分享描述
    link: string, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: string, // 分享图标
}, trackCode: string, id?: string, type?: string) {
    window.wx?.ready(() => {
            if (id) {
                window.wx.onMenuShareTimeline({
                    success() {
                        // 用户点击了分享后执行的回调函数
                        window.aplus_queue.push({
                            action: 'aplus.record',
                            arguments: ["ShareClickTimeline", "CLK", {type, id}]
                        });
                    }
                })
                window.wx.onMenuShareAppMessage({
                    success() {
                        // 用户点击了分享后执行的回调函数
                        window.aplus_queue.push({
                            action: 'aplus.record',
                            arguments: ["ShareClickAppMessage", "CLK", {type, id}]
                        });
                    }
                });
            }

            if (trackCode) {
                shareData.link = appendTrackCodeToLink(shareData.link, trackCode)
            }

            window.wx.updateAppMessageShareData(shareData) // 好友
            window.wx.updateTimelineShareData(shareData); // 朋友圈
            if (!trackCode) {
                // 订阅更新
                subscribeTrackCodeChange().then((code) => {
                    if (code) {
                        shareData.link = appendTrackCodeToLink(shareData.link, code)
                        window.wx.updateAppMessageShareData(shareData) // 好友
                        window.wx.updateTimelineShareData(shareData); // 朋友圈
                    }
                })
            }
        }
    )
}


function appendTrackCodeToLink(link: string, trackCode: string): string {
    const arr = link.split('?')
    const search = new URLSearchParams(arr[1])
    // 去除code state
    search.delete('state')
    search.delete('code')
    if (search.has('trackCode')) {
        search.set('trackCode', trackCode)
    } else {
        search.append('trackCode', trackCode)
    }
    return `${arr[0]}?${search.toString()}`
}
