export default function funcFilterSelected(item: any, state: Array<{ id: any }>, setState: Function) {
    let has
    const _filterSeleted = state.filter(({id}: any) => {
        if (id === item.id) {
            has = true
            return false
        }
        return true
    })
    if (!has) {
        _filterSeleted.push(item)
    }
    setState(_filterSeleted)
    return _filterSeleted
}